import { Component, Prop, Vue } from 'vue-property-decorator'
import moment from 'moment'

@Component({
  name: 'SettingChips'
})
export default class SettingChips extends Vue {
  @Prop({ required: true, type: Array, default: () => [] })
  registrationTypes!: Array<any>

  @Prop({ required: true, type: Object, default: () => ({}) })
  setting!: Record<string, any>

  @Prop({ required: true, type: Boolean, default: false })
  disabled!: boolean

  @Prop({ required: false, type: Number, default: 12 })
  cols!: number

  get values () {
    return this.setting.value
  }

  regTypeName (value: string) {
    const _value: any = this.registrationTypes.filter((reg_type: any) => reg_type.value === value)
    if (_value.length) {
      return this.capitalizeText(_value[0].text)
    }
  }

  capitalizeText (value: string) {
    return value.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
  }

  resetValue (registration_type: string) {
    this.$emit('reset-value', this.values, registration_type)
  }

  useValue (reg_setting) {
    this.$emit('use-setting-value', this.setting, reg_setting)
  }

  formatValue (value: any) {
    if (value instanceof Date) {
      return moment(value).format('YYYY-MM-DD HH:mm')
    } else {
      return value
    }
  }
}
