import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  name: 'GtrCompanyPlansView',
  computed: {
    ...mapState('event', ['events'])
  }
})
export default class GtrCompanyPlansView extends Vue {
  data () {
    return {
      tab: 0,
      registerFeatures: [
        {
          text: 'Import & export participants',
          included: true
        },
        {
          text: 'Manage participant lists',
          included: true
        },
        {
          text: 'No limit on registration types',
          included: true
        },
        {
          text: 'Offer discount codes & early bird pricing',
          included: true
        },
        {
          text: 'Filter & sort participants by custom groups',
          included: true
        },
        {
          text: 'Create custom registration paths',
          included: true
        },
        {
          text: 'Unlimited conditional logic',
          included: true
        },
        {
          text: 'Flexible content pages',
          included: true
        },
        {
          text: 'Drag & drop form creation',
          included: true
        },
        {
          text: 'Send automated emails',
          included: true
        },
        {
          text: 'Process payments & refunds',
          included: true
        },
        {
          text: 'Secure, ad-free, & private',
          included: true
        },
        {
          text: 'Showcase sponsors',
          included: true
        },
        {
          text: 'Add custom CSS & Javascript',
          included: true
        },
        {
          text: 'Add Google Analytics',
          included: true
        },
        {
          text: 'PCI, GDPR, CCPA compliant',
          included: true
        },
        {
          text: 'Language translation',
          included: true
        },
        {
          text: 'Blacklist email domains',
          included: true
        },
        {
          text: 'Create trackable referral codes',
          included: true
        },
        {
          text: 'Real-time reporting & analytics',
          included: true
        },
        {
          text: 'Embed registration forms',
          included: false
        }
      ],
      integrationItems: [
        {
          text: 'Zapier integrations',
          included: true
        },
        {
          text: 'Native integrations',
          included: true
        },
        {
          text: 'Custom integrations',
          included: false
        }
      ],
      supportItems: [
        {
          text: '24/7 access to online knowledge base',
          included: true
        },
        {
          text: 'Email support',
          included: true
        },
        {
          text: 'Dedicated event success team',
          included: false
        },
        {
          text: 'On-site event success team',
          included: false
        },
        {
          text: '1:1 Onboarding & Advising',
          included: false
        }
      ],
      leadsFeatures: [
        {
          text: 'Manage exhibitors',
          included: true
        },
        {
          text: 'Offer multiple device options',
          included: true
        },
        {
          text: 'Branded order form',
          included: true
        },
        {
          text: 'Process payments & refunds',
          included: true
        },
        {
          text: 'Manage pricing',
          included: true
        },
        {
          text: 'Real-time reporting & analytics',
          included: true
        },
        {
          text: 'Offer proscanner devices',
          included: false
        }
      ],
      trackFeatures: [
        {
          text: 'Manage Sessions',
          included: true
        },
        {
          text: 'Attendance tracking hardware & software',
          included: true
        },
        {
          text: 'Multiple device options',
          included: true
        },
        {
          text: 'Unlimited activations',
          included: true
        },
        {
          text: 'Real-time reporting & analytics',
          included: true
        },
        {
          text: 'Automated quizzes & surveys',
          included: false
        },
        {
          text: 'Control session access',
          included: false
        },
        {
          text: 'Generate session attendance certificates',
          included: false
        },
        {
          text: 'Automated CEU evaluations',
          included: false
        }
      ],
      badgesFeatures: [
        {
          text: 'On-site badge printing hardware & software',
          included: true
        },
        {
          text: 'Branded check-in kiosks',
          included: true
        },
        {
          text: 'Generate unique QR codes or barcodes',
          included: true
        },
        {
          text: 'Add attendees on-site',
          included: true
        },
        {
          text: 'Badge preview',
          included: true
        },
        {
          text: 'Import attendee lists',
          included: true
        },
        {
          text: 'Showcase sponsors',
          included: true
        },
        {
          text: 'Real-time reporting & analytics',
          included: true
        },
        {
          text: 'Badge templates',
          included: true
        },
        {
          text: 'Eco-friendly badges',
          included: true
        },
        {
          text: 'Custom badge sizes',
          included: false
        }
      ]
    }
  }
}
