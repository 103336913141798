import ChangeLog from '@/modules/common/components/changelog/changelog.vue'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component, Watch, Mixins } from 'vue-property-decorator'
import SettingsPageMixin from '@/modules/common/components/mixins/settings-page.mixin'
import DesignPageMixin from '@/modules/common/components/mixins/design-page.mixin'

@Component({
  name: 'GtrLeadsModuleDesignView',
  components: {
    changelog: ChangeLog
  }
})
export default class GtrLeadsModuleDesignView extends Mixins(SettingsPageMixin, DesignPageMixin) {
  private readonly settings_type = 'lr_design'

  regTypeValue (name: string) {
    const _value: any = this.registrationTypes.filter((reg_type: any) => reg_type.text.toLowerCase() === name)
    if (_value.length) {
      return _value[0].value
    }
  }

  // #region Life Cycle Callbacks
  async mounted () {
    this.$store.commit('design/SET_DESIGN', [])
    this.$store.commit('design/SET_DESIGN_TEMPLATES', [])
    this.$store.commit('design/SET_DESIGN_FIELDS', [])
    this.$store.commit('option/SET_OPTION_GROUPS', [])
    await this.fetchSettings('design/getLRDesign')
    await this.fetchSettingsFields('design/getLRDesignFields')
    await this.fetchChangelog(this.settings_type)
    await this.fetchCurrentlyDeployedLiveUUID(this.settings_type)
    this.$store.dispatch('common/hideLoader')
  }
  // #endregion

  // #region Watchers
  @Watch('designVersion')
  async onDesignVersionChange (payload: any) {
    if (payload !== 'default') {
      try {
        this.loading = true
        const revisionUuid = this.$data._changelog.data.filter((row: any) => row.uuid === payload).pop().uuid
        await this.$store.dispatch('design/getSettingsByRevisionUuid', { event_uuid: this.event_uuid, revision_uuid: revisionUuid })
        Container.get(Notification).success('Design version successfully changed.')
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.loading = false
      }
    }
  }

  @Watch('designLoaded', { deep: true })
  onDesignLoaded (payload: any) {
    if (payload) {
      const designObject: Record<string, any> = {}
      const languages = this.$store.state.languages.languages
      for (const category in this.designFields) {
        designObject[category] = {}
        for (const setting in this.designFields[category]) {
          designObject[category][setting] = {}
          designObject[category][setting].name = setting
          /**
           * Add type to the setting
           */
          designObject[category][setting].type = this.designFields[category][setting].type
          designObject[category][setting].display_name = this.designFields[category][setting].display_name
          designObject[category][setting].description = this.designFields[category][setting].description
          designObject[category][setting].value = {}
          designObject[category][setting].temp = null
          for (let i = 0; i < this.registrationTypes.length; i++) {
            const regTypeUUID = this.registrationTypes[i].value
            const settingType = this.designFields[category][setting].type
            if (
              settingType === 'localized_html' ||
              settingType === 'html'
            ) {
              designObject[category][setting].value[regTypeUUID] = {}
              if (settingType === 'localized_html') {
                for (let i = 0; i < languages.length; i++) {
                  const {
                    name: languageName
                  } = languages[i]
                  if (!designObject[category][setting].value[regTypeUUID][languageName]) {
                    designObject[category][setting].value[regTypeUUID][languageName] = ''
                  }
                }
              } else {
                designObject[category][setting].value[regTypeUUID] =
                  typeof designObject[category][setting].default !== 'undefined'
                    ? designObject[category][setting].default
                    : ''
              }
            } else {
              designObject[category][setting].value[regTypeUUID] =
                typeof designObject[category][setting].default !== 'undefined'
                  ? designObject[category][setting].default
                  : ''
            }
          }
        }
      }
      if (this.design.page_data) {
        for (const setting in this.design.page_data) {
          for (const category in designObject) {
            if (designObject[category][setting]) {
              for (const value in this.design.page_data[setting]) {
                if (designObject[category][setting].type === 'color') {
                  designObject[category][setting].value[value] = this.design.page_data[setting][value] === null
                    ? ''
                    : this.design.page_data[setting][value]
                } else {
                  designObject[category][setting].value[value] = this.design.page_data[setting][value]
                }
              }
            }
          }
        }
      }

      this.settingsObject = designObject

      const templateObject = {}
      for (let i = 0; i < this.registrationTypes.length; i++) {
        const { value } = this.registrationTypes[i]
        templateObject[value] = {
          current_template: 'default',
          template_data: Object.assign({}, this.templateSettings)
        }
      }

      this.template = templateObject
    }
  }
  // #endregion

  async submit () {
    try {
      this.submitting = true
      const designData: Record<string, any> = {
        event_uuid: this.event_uuid
      }

      // Sending files to Vapor if exists
      // TODO(zb): This will need to be revisited once templates get handled.
      const key = this.templateToUse
      for (const setting in this.settingsObject[key]) {
        if (this.settingsObject[key][setting].type === 'url') {
          for (const temp_file in this.settingsObject[key][setting].value) {
            if (this.settingsObject[key][setting].value[temp_file] && temp_file.split('_')[1] === 'temp') {
              const file = this.settingsObject[key][setting].value[temp_file]
              const response: any = await this.$store.dispatch('media/uploadMedia', { event_uuid: this.event_uuid, fileData: file, fetch: false })
              const url = response.responseEventMedia.data.url
              if (temp_file.split('_')[0] === 'default') { // never true. there is no default file type.
                this.settingsObject[key][setting].value._default = url
              } else {
                this.settingsObject[key][setting].value[this.regTypeValue(temp_file.split('_')[0])] = url
              }
            }
          }
        }
      }

      // loop through every setting in the design object.
      for (const category in this.settingsObject) {
        for (const setting in this.settingsObject[category]) {
          if (this.settingsObject[category][setting].type === 'url') { // if the type is a url.
            const temp = this.settingsObject[category][setting].value // store the value of the setting.
            // setup a loop.
            let j = 0
            let count = Object.keys(temp).length

            // while j is less then count.
            while (j < count) {
              if (Object.keys(temp)[j].includes('temp')) { // if it has the word temp, delete it.
                delete temp[Object.keys(temp)[j]]
                count-- // decrement count.
              }
              j++ // increment j.
            }
            delete temp.temp_file
            designData[setting] = temp
          } else {
            designData[setting] = this.settingsObject[category][setting].value
          }
        }
      }
      /**
       * Add template stored in data() to the designData object
       */
      designData.template = this.template
      designData.template_name = this.templateToUse
      const payload: any = {
        event_uuid: this.event_uuid,
        data: designData
      }
      await this.$store.dispatch('design/saveLRDesign', payload)
      Container.get(Notification).success('Design successfully saved.')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.submitting = false
    }
  }

  // #endregion
}
