import Container from 'typedi'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import ApiCredentials from '../models/api-credentials.model'
import ChangePassword from '../models/change-password.model'
import Register from '../models/register.model'
import ResetPassword from '../models/reset-password.model'
import SecurityContext from '../models/security-context.model'
import SecurityService from '../services/security.service'
import GtrStorage from '../services/storage.service'
import FileUploadService from '@/modules/common/services/file-upload.service'

@Module({
  namespaced: true
})
export default class SecurityStore extends VuexModule {
  securityContext: SecurityContext | undefined = undefined
  activationStatus: boolean | undefined = undefined
  twofactorSent: boolean | undefined = false
  currentUser: any | undefined = undefined
  paymentMethods: any[] = []
  user: Record<string, any> | null = null
  zapierKeys: any = []

  get getSecurityContext (): SecurityContext | undefined {
    return this.securityContext
  }

  @Mutation
  SET_ZAPIER_KEYS (payload: any) {
    if (payload) {
      this.zapierKeys = payload
    } else {
      this.zapierKeys = []
    }
  }

  @Mutation
  SET_SECURITY_CONTEXT (securityContext: SecurityContext | undefined) {
    this.securityContext = securityContext
  }

  @Mutation
  SET_ACTIVATION_STATUS (status: boolean) {
    this.activationStatus = status
  }

  @Mutation
  SET_CURRENT_USER (payload: any) {
    this.currentUser = payload
  }

  @Mutation
  SET_PAYMENT_METHODS (payload: any[]) {
    this.paymentMethods = payload
  }

  @Mutation
  SET_USER (payload: any) {
    this.user = payload
  }

  @Mutation
  SET_TWO_FACTOR_SENT (payload: any) {
    this.twofactorSent = payload
  }

  @Action
  setSecurityContext (paylaod: SecurityContext) {
    this.context.commit('SET_SECURITY_CONTEXT', paylaod)
  }

  @Action({ rawError: true })
  async login (payload: ApiCredentials) {
    const response: any = await Container.get(SecurityService).login(payload)
    if (response.data.authorization_type === '2fa') {
      const _payload = {
        data: {
          email: payload.email,
          password: payload.password
        }
      }
      this.context.dispatch('get2faCode', _payload)
    } else {
      this.context.commit('SET_SECURITY_CONTEXT', response.data)
      const context = JSON.stringify({
        access_token: response.data.access_token,
        access_level: response.data.user.access_level,
        user: response.data.user,
        company: response.data.user.company,
        expires_in: response.data.expires_in,
        has_company: response.data.user.company !== null
      })
      Container.get(GtrStorage).setItem('security_context', context)
    }
    return response.data
  }

  @Action({ rawError: true })
  async refreshToken (ttl: number) {
    const response: any = await Container.get(SecurityService).refreshToken(ttl)
    this.context.commit('SET_SECURITY_CONTEXT', response.data)
    Container.get(GtrStorage).setItem(
      'security_context',
      JSON.stringify({
        access_token: response.data.access_token,
        access_level: response.data.user.access_level,
        user: response.data.user,
        company: response.data.user.company,
        expires_in: response.data.expires_in,
        has_company: response.data.user.company !== null
      })
    )
    return response.data
  }

  @Action({ rawError: true })
  async register (payload: Register) {
    const response = await Container.get(SecurityService).register(payload)
    return response
  }

  @Action({ rawError: true })
  async activateAccount (payload: string) {
    const response: any = await Container.get(SecurityService).activateAccount(
      payload
    )
    if (response.data.user.uuid) {
      this.context.commit('SET_ACTIVATION_STATUS', true)
    } else {
      this.context.commit('SET_ACTIVATION_STATUS', false)
    }
    return response
  }

  @Action({ rawError: true })
  async logout () {
    await Container.get(SecurityService).logout()
    Container.get(GtrStorage).removeItem('security_context')
  }

  @Action({ rawError: true })
  async resetPassword (payload: ResetPassword) {
    await Container.get(SecurityService).resetPassword(payload)
  }

  @Action({ rawError: true })
  async changePassword (payload: ChangePassword) {
    await Container.get(SecurityService).changePassword(payload)
  }

  @Action({ rawError: true })
  async usePasswordToken (token: string) {
    const response = await Container.get(SecurityService).usePasswordToken(token)
    return response
  }

  @Action({ rawError: true })
  async generateZapierKey (payload) {
    const response = await Container.get(SecurityService).generateZapierKey(payload)
    await this.context.dispatch('getZapierKeys')
    return response
  }

  @Action({ rawError: true })
  async updateZapierKey (payload) {
    const response = await Container.get(SecurityService).updateZapierKey(payload)
    await this.context.dispatch('getZapierKeys')
    return response
  }

  @Action({ rawError: true })
  async getZapierKeys () {
    const response = await Container.get(SecurityService).getZapierKeys()
    this.context.commit('SET_ZAPIER_KEYS', response.data)
    return response
  }

  @Action({ rawError: true })
  async stopImpersonating () {
    const response = await Container.get(SecurityService).stopImpersonating()
    Container.get(GtrStorage).setItem(
      'security_context',
      JSON.stringify({
        access_token: response.data.access_token,
        access_level: response.data.user.access_level,
        user: response.data.user,
        company: response.data.user.company,
        expires_in: response.data.expires_in,
        has_company: response.data.user.company !== null
      })
    )
    return response
  }

  @Action({ rawError: true })
  async impersonateUser (user_uuid: string) {
    const response = await Container.get(SecurityService).impersonateUser(user_uuid)
    Container.get(GtrStorage).setItem(
      'security_context',
      JSON.stringify({
        access_token: response.data.access_token,
        access_level: response.data.user.access_level,
        user: response.data.user,
        company: response.data.user.company,
        expires_in: response.data.expires_in,
        has_company: response.data.user.company !== null,
        impersonator: response.data.impersonator
      })
    )
    return response
  }

  @Action({ rawError: true })
  async getCurrentUser () {
    const response = await Container.get(SecurityService).getCurrentUser()
    this.context.commit('SET_CURRENT_USER', response.data)
    return response
  }

  @Action({ rawError: true })
  async getPaymentMethods () {
    const response = await Container.get(SecurityService).getPaymentMethods()
    this.context.commit('SET_PAYMENT_METHODS', response.data)
    return response
  }

  @Action({ rawError: true })
  async deleteAccess (payload: any) {
    const { user_uuid, event_uuid } = payload
    const response = await Container.get(SecurityService).deleteAccess(user_uuid, event_uuid)
    this.context.dispatch('getUser', payload)
    return response
  }

  @Action({ rawError: true })
  async addAccess (payload: any) {
    const { user_uuid, event_uuid } = payload
    const response = await Container.get(SecurityService).addAccess(user_uuid, event_uuid)
    this.context.dispatch('getUser', payload)
    return response
  }

  @Action({ rawError: true })
  async getUser (payload: any) {
    const { user_uuid } = payload
    const response = await Container.get(SecurityService).getUser(user_uuid)
    this.context.commit('SET_USER', response.data)
    return response
  }

  @Action({ rawError: true })
  async updateCurrentUser (payload: any) {
    const response = await Container.get(SecurityService).updateCurrentUser(payload)
    this.context.dispatch('getCurrentUser')
    return response
  }

  @Action({ rawError: true })
  async updateUser (payload: any) {
    const { user_uuid, data } = payload
    if (data?.profile_photo && data.profile_photo instanceof File) {
      const profile_photo = await Container.get(FileUploadService).uploadFile(data.profile_photo)
      data.profile_photo = profile_photo
    }
    await Container.get(SecurityService).updateUser(user_uuid, data)
    return await this.context.dispatch('getUser', payload)
  }

  @Action({ rawError: true })
  async get2faCode (payload: any) {
    const { data } = payload
    const response = await Container.get(SecurityService).get2faCode(data)
    this.context.commit('SET_TWO_FACTOR_SENT', true)
    return response
  }

  @Action({ rawError: true })
  async send2FAToken () {
    return await Container.get(SecurityService).send2FAToken()
  }

  @Action({ rawError: true })
  async verify2FACode (payload: any) {
    return await Container.get(SecurityService).verify2FAToken(payload)
  }

  @Action({ rawError: true })
  async addEventAccess (payload: any) {
    const { event_uuid, user_uuid } = payload
    const response = await Container.get(SecurityService).addEventAccess(event_uuid, user_uuid)
    return response
  }

  @Action({ rawError: true })
  async addEventAccessBulk (payload: any) {
    const { user_uuid, add_events } = payload
    const results = await Container.get(SecurityService).addEventAccessBulk(user_uuid, add_events)
    this.context.dispatch('getUser', { user_uuid })
    return results
  }

  @Action({ rawError: true })
  async removeEventAccess (payload: any) {
    const { event_uuid, user_uuid } = payload
    const response = await Container.get(SecurityService).removeEventAccess(event_uuid, user_uuid)
    this.context.dispatch('getUser', { user_uuid })
    return response
  }

  @Action({ rawError: true })
  async removeEventAccessBulk (payload: any) {
    const { user_uuid, remove_events } = payload
    const results = await Container.get(SecurityService).removeEventAccessBulk(user_uuid, remove_events)
    this.context.dispatch('getUser', { user_uuid })
    return results
  }
}
