import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { AccessLevel } from '@/modules/common/enums/access-levels.enum'

@Component({
  name: 'GtrEditUserGroupForm'
})
export default class GtrEditserGroupForm extends GtrSuper {
    @Prop({ required: true, type: Boolean, default: false })
    visible: boolean|undefined

    @Prop({ required: true, type: Object, default: {} })
    user_group: object|any

    data () {
      return {
        value: false,
        submitting: false
      }
    }

    @Watch('visible', { immediate: true })
    onVisibiltyChange (payload: any) {
      this.$data.value = payload
    }

    onClose () {
      const observer = (this.$refs.observerForm as any)
      observer.reset()
      this.$data.value = false
      this.$emit('close')
    }

    handleIfNotAbleToEditName (user_group) {
      return (user_group.name === AccessLevel.SUPER_ADMIN ||
        user_group.name === AccessLevel.RESELLER_ADMIN ||
        user_group.name === AccessLevel.SELF_SERVICE_ADMIN ||
        user_group.name === AccessLevel.COMPANY_ADMIN ||
        user_group.name === AccessLevel.COMPANY_USER ||
        user_group.name === AccessLevel.COMPANY_REPORT_USER) ||
        user_group.users_count > 0
    }

    async submit () {
      const form = (this.$refs.newUserForm as HTMLFormElement)
      if (form.validate()) {
        try {
          this.$data.submitting = true
          const payload: any = {
            uuid: this.user_group.uuid,
            data: {
              name: this.user_group.name,
              description: this.user_group.description
            }
          }
          await this.$store.dispatch('company/editUserGroup', payload)
          Container.get(Notification).success('User Group successfully updated.')
          this.$data.user_group = {
            name: null,
            description: null
          }
          this.onClose()
        } catch (error) {
          Container.get(ErrorHandlerService).error(error)
        } finally {
          this.$data.submitting = false
        }
      }
    }
}
