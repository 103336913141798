import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'SettingApplicator'
})
export default class SettingApplicator extends Vue {
  @Prop({ required: true, type: Array, default: () => [] })
  registrationTypes!: Array<any>

  @Prop({ required: true, type: Object, default: () => ({}) })
  setting!: Record<string, any>

  @Prop({ required: false, type: String, default: '' })
  tab!: string

  @Prop({ required: false, type: Boolean, default: false })
  div!: boolean

  get disabled () {
    if (['fields', 'fieldswithwidth'].includes(this.setting.type)) {
      return !this.setting.temp_field && !this.setting.temp_label
    } else {
      return !this.setting.temp
    }
  }

  get wrapper () {
    return this.div ? 'div' : 'v-col'
  }

  applySetting (reg_type) {
    this.$emit('update-type', this.setting, reg_type, this.tab)
  }
}
