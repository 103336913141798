import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import Container, { Service } from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import { mapState, mapGetters } from 'vuex'
import GtrModuleLayout from '@/modules/common/views/layouts/level-two/module/module.layout.vue'

@Service()
@Component({
  name: 'SettingsPageMixin',
  computed: {
    ...mapState('formbuilder', ['reportableEventFields']),
    ...mapState('languages', ['languages']),
    ...mapState('event', ['changelog', 'currentlyDeployedLiveUUID']),
    ...mapGetters('option', ['registrationTypes'])
  }
})
export default class SettingsPageMixin extends Vue {
  registrationTypes!: Array<any> // vuex binding

  // internal state
  event_uuid: string = this.$route.params.event_uuid
  company_uuid: string = this.$route.params.uuid
  registrationTypeKey = '_default'
  languageToUse = 'en'
  submitting = false
  loading = false
  settingsVersion = ''
  settingsObject: Record<string, any> = {}
  fieldsPrint: Array<any> = []

  @Watch('languages')
  onLanguagesChange (languages: any[]) {
    if (languages) {
      this.languageToUse = 'en'
    }
  }

  @Watch('changelog')
  onChangeLogChange (payload: any) {
    if (payload) {
      this.$data._changelog = payload
    }
  }

  @Watch('currentlyDeployedLiveUUID')
  onCurrentlyDeployedLiveUUIDChange (payload: any) {
    if (payload.live) {
      this.$data._currentlyDeployedLiveUUID = payload.live.uuid
    }
  }

  @Watch('providerFields')
  onProviderFieldsChange () {
    this.$data.providerDialog = true
  }

  data (): Record<string, any> {
    return {
      settingsVersion: 'default',
      providerDialog: false,
      _changelog: null,
      _currentlyDeployedLiveUUID: null,
      _currentlyDeployedDevUUID: null
    }
  }

  async mounted () {
    this.$store.dispatch('common/showLoader', { value: true })
    await this.fetchLanguages()
    await this.fetchOptionGroups()
    await this.fetchCompanyBlacklists()
    await this.fetchContentPages()
    await this.fetchAllContent()
    await this.fetchReportableEventFields()
  }

  updated () {
    this.$emit('update:layout', GtrModuleLayout)
  }

  updateSettingsVersion (uuid) {
    this.settingsVersion = uuid
  }

  showOrHideSettingsFields () {
    const settings_with_show_if: any = []
    for (const setting in this.settingsObject) {
      if (this.settingsObject[setting].hasOwnProperty('show_if')) {
        settings_with_show_if.push(this.settingsObject[setting])
      }
    }
    for (let i = 0; i < settings_with_show_if.length; i++) {
      const setting_name = settings_with_show_if[i].name
      const setting_show_if = settings_with_show_if[i].show_if
      /**
       * Get the field and the current value of the setting
       */
      const show_if_field = setting_show_if.field
      const show_if_operator = setting_show_if.operator
      const show_if_value = setting_show_if.value

      if (
        this.settingsObject[show_if_field].type === 'localized' ||
        this.settingsObject[show_if_field].type === 'localized_html'
      ) {
        if (
          this.$data.operatorFunctions[show_if_operator](
            this.settingsObject[show_if_field].value[this.registrationTypeKey][this.languageToUse],
            show_if_value
          )
        ) {
          this.settingsObject[setting_name].show_field = true
        } else {
          this.settingsObject[setting_name].show_field = false
        }
      } else {
        if (
          this.$data.operatorFunctions[show_if_operator](
            this.settingsObject[show_if_field].value[this.registrationTypeKey],
            show_if_value
          )
        ) {
          this.settingsObject[setting_name].show_field = true
        } else {
          this.settingsObject[setting_name].show_field = false
        }
      }
    }
  }

  protected async fetchSettings (dispatch_type: string, commit_type?: string) {
    try {
      await this.$store.dispatch(dispatch_type, { event_uuid: this.event_uuid })
    } catch (error) {
      if (error.data.error_code !== 'CONTENT_NOT_FOUND') {
        if (commit_type) {
          this.$store.commit(commit_type, [])
        }
        Container.get(ErrorHandlerService).error(error)
      }
    }
  }

  protected async fetchSettingsFields (dispatch_type) {
    try {
      await this.$store.dispatch(dispatch_type)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  protected async fetchChangelog (type) {
    try {
      const changelogData = {
        type,
        event_uuid: this.event_uuid,
        subtype: 'default/all?no_data=true'
      }
      await this.$store.dispatch('event/getChangelog', changelogData)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  protected async fetchCurrentlyDeployedLiveUUID (type) {
    try {
      const deployLiveData = {
        type,
        event_uuid: this.event_uuid,
        sub_type: 'default'
      }
      await this.$store.dispatch('event/getCurrentlyDeployedLiveUUID', deployLiveData)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  protected async fetchLanguages () {
    try {
      await this.$store.dispatch('languages/fetchLanguages', this.event_uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  protected async fetchOptionGroups () {
    try {
      await this.$store.dispatch('option/getOptionsGroup', { event_uuid: this.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  protected async fetchAllContent () {
    try {
      await this.$store.dispatch('event/getEventAllContent', this.event_uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  protected async fetchCompanyBlacklists () {
    try {
      await this.$store.dispatch('company/getCompanyBlacklists', { company_uuid: this.company_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  protected async fetchContentPages () {
    try {
      await this.$store.dispatch('contentpages/getContentPages', { event_uuid: this.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  protected async fetchReportableEventFields () {
    try {
      await this.$store.dispatch('formbuilder/getReportableEventFields', { event_uuid: this.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }
}
