import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { Component, Watch } from 'vue-property-decorator'
import router from '@/bootstrap/router/router'
import Container from 'typedi'
import EventService from '@/modules/common/services/event/event.service'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import { mapState } from 'vuex'
import moment from 'moment'
import { DeviceNameEnum } from '@/modules/level-two/enums/device-name.enum'

Component.registerHooks([
  'beforeRouteEnter'
])
@Component({
  name: 'GtrLeadsView',
  computed: {
    ...mapState('event', ['event']),
    ...mapState('leads', ['leads', 'leadsStats', 'exhibitorStats']),
    ...mapState('email', ['emails'])
  }
})
export default class GtrLeadsView extends GtrSuper {
  data () {
    return {
      loading: false,
      areaChartOptions: {
        legend: {
          position: 'top'
        },
        colors: ['#1F4746'],
        vAxis: {
          format: 0,
          viewWindow: {
            min: null,
            max: '100%'
          }
        }
      },
      boxLinks: [
        {
          title: 'GTR Leads Report',
          description: 'View and export lead retrieval reports by attendee or exhibitor.',
          icon: 'mdi-view-list',
          link: 'leads/reports',
          type: 'icon',
          permission: 'leads.lead_retrieval_reports.view'
        },
        {
          title: 'Setup',
          description: 'Select which devices to offer, manage pricing, default qualifiers, and payment processing.',
          icon: 'mdi-tune',
          link: 'leads/setup',
          type: 'icon',
          permission: 'leads.setup.view'
        },
        {
          title: 'Emails',
          description: 'Design and send custom html emails or get a head start with one of our email templates.',
          icon: 'mdi-email-outline',
          link: 'emails',
          type: 'icon',
          permission: 'general.emails.view'
        },
        {
          title: 'Support',
          description: 'Need help? Visit our knowledge base or get in touch with us for assistance.',
          image: 'modules/support - grayscale.svg',
          link: 'support',
          type: 'image',
          permission: 'general.support.view'
        },
        {
          title: 'Device Check-in/Out',
          description: 'Your on-site dashboard for checking in/out lead retrieval devices and sending SMS notifications.',
          icon: 'mdi-cellphone-check',
          link: 'leads/device-check-in-out',
          type: 'icon',
          permission: 'leads.device_check_in_out.view'
        },
        {
          title: 'Promote',
          description: 'Sell more apps and devices.  Download a pdf to market to exhibitors on-site.',
          icon: 'mdi-star',
          link: 'leads/promote',
          type: 'icon',
          permission: null
        },
        {
          title: 'Settings',
          description: 'View and customize your settings to maximize your event efficiency.',
          icon: 'mdi-cog',
          link: 'leads/settings',
          type: 'icon',
          permission: 'leads.settings.view'
        }
      ],
      table: {
        search: '',
        tab: 0,
        headers: [],
        items: []
      },
      event_uuid: this.$route.params.event_uuid,
      registration_uuid: '',
      to: '',
      cc: '',
      bcc: '',
      email_name: 'your_leads'
    }
  }

  getStatusColor (status: string): string {
    switch (status) {
      case 'Complete':
        return '#43AF49'
      case 'Incomplete':
        return '#F89C22'
      case 'Cancelled':
        return '#EF4923'
      default:
        return '#000000'
    }
  }

  navigateTo (link: string) {
    if (!link) {
      return
    }
    const url = `/company/${this.$route.params.uuid}/event/${this.$route.params.event_uuid}/${link}`
    this.$router.push(url)
  }

  goToOrdersTab (tab: number) {
    this.$data.table.tab = tab
    const ordersTable = this.$refs.ordersTable as HTMLElement
    if (ordersTable) {
      ordersTable.scrollIntoView({ behavior: 'smooth' })
    }
  }

  get tableHeaders (): any[] {
    const leadsStats = this.$store.state.leads.leadsStats
    const headers: any[] = [
      { text: 'Payment Status', align: 'start', sortable: true, value: 'lr_status' },
      { text: 'Email', align: 'start', sortable: true, value: 'email' },
      { text: 'First', align: 'start', sortable: true, value: 'first_name' },
      { text: 'Last', align: 'start', sortable: true, value: 'last_name' },
      { text: 'Company', align: 'start', sortable: true, value: 'company' },
      { text: 'Proscanner Orders', align: 'start', sortable: false, filterable: false, value: DeviceNameEnum.Proscanner }
    ]
    const ordersByDevice: any[] = Object.entries(leadsStats?.total_orders_by_device)
    for (let i = 0; i < ordersByDevice.length; i++) {
      if (ordersByDevice[i][0].includes('Lead Retrieval Activations:')) {
        headers.push({
          text: ordersByDevice[i][0],
          align: 'start',
          sortable: false,
          filterable: false,
          value: ordersByDevice[i][0]
        })
      }
    }
    headers.push({ text: 'Activation Codes', align: 'start', sortable: false, filterable: false, value: 'codes' })
    return headers
  }

  get tableItems (): any[] {
    const items: any[] = []
    switch (this.$data.table.tab) {
      case 1:
        for (let i = 0; i < this.$data.table.items.length; i++) {
          const item = this.$data.table.items[i]
          const arr: any[] = Object.entries(item)
          for (let j = 0; j < arr.length; j++) {
            if (arr[j][0].includes('Lead Retrieval Activations:') && arr[j][1] > 0) {
              items.push(item)
            }
          }
        }
        return items
        break
      case 2:
        return this.$data.table.items.filter((item: any) => item[DeviceNameEnum.Proscanner] > 0)
        break
      default:
        return this.$data.table.items
    }
  }

  get totalScans () {
    if (!this.$store.state.leads.leadsStats) {
      return 0
    }
    return this.totalProscannerScans + this.totalMobileAppScans
  }

  get totalPayments () {
    if (!this.$store.state.leads.leadsStats) {
      return 0
    }
    return this.$store.state.leads.leadsStats.total_payments
  }

  get totalOrders () {
    if (!this.$store.state.leads.leadsStats) {
      return 0
    }
    return this.$store.state.leads.leadsStats.total_orders
  }

  get totalProscannerScans () {
    if (!this.$store.state.leads.leadsStats || !this.$store.state.leads.leadsStats.total_scans_by_device?.[DeviceNameEnum.Proscanner]) {
      return 0
    }
    return this.$store.state.leads.leadsStats.total_scans_by_device[DeviceNameEnum.Proscanner]
  }

  get totalMobileAppScans () {
    const { leadsStats } = this.$store.state.leads
    if (!leadsStats) {
      return 0
    }
    const mobileAppOrders = leadsStats?.total_scans_by_device?.[DeviceNameEnum.MobileApp] || 0
    const leadRetrievalActivationsOneOrders = leadsStats?.total_scans_by_device?.[DeviceNameEnum.LeadRetrievalActivationsOne] || 0
    const leadRetrievalActivationsThreeOrders = leadsStats?.total_scans_by_device?.[DeviceNameEnum.LeadRetrievalActivationsThree] || 0
    const leadRetrievalActivationsFiveOrders = leadsStats?.total_scans_by_device?.[DeviceNameEnum.LeadRetrievalActivationsFive] || 0
    const leadRetrievalActivationsUnlimitedOrders = leadsStats?.total_scans_by_device?.[DeviceNameEnum.LeadRetrievalActivationsUnlimited] || 0
    return mobileAppOrders + leadRetrievalActivationsOneOrders + leadRetrievalActivationsThreeOrders + leadRetrievalActivationsFiveOrders + leadRetrievalActivationsUnlimitedOrders
  }

  get totalProscannerOrders () {
    if (!this.$store.state.leads.leadsStats || !this.$store.state.leads.leadsStats.total_orders_by_device?.[DeviceNameEnum.Proscanner]) {
      return 0
    }
    return this.$store.state.leads.leadsStats.total_orders_by_device[DeviceNameEnum.Proscanner]
  }

  get totalMobileAppOrders () {
    const { leadsStats } = this.$store.state.leads
    if (!leadsStats) {
      return 0
    }
    const leadRetrievalActivations = leadsStats?.total_orders_by_device?.[DeviceNameEnum.LeadRetrievalActivations] || 0
    return leadRetrievalActivations
  }

  get totalProscannerPayments () {
    if (!this.$store.state.leads.leadsStats || !this.$store.state.leads.leadsStats.total_payments_by_device?.[DeviceNameEnum.Proscanner]) {
      return 0
    }
    return this.$store.state.leads.leadsStats.total_payments_by_device[DeviceNameEnum.Proscanner]
  }

  get totalMobileAppPayments () {
    const { leadsStats } = this.$store.state.leads
    if (!leadsStats) {
      return 0
    }
    const leadRetrievalActivations = leadsStats?.total_payments_by_device?.[DeviceNameEnum.LeadRetrievalActivations] || 0
    return leadRetrievalActivations
  }

  progressBarAmount (num: number): number {
    if (this.totalScans === 0) {
      return 15
    }
    const percentageAsInt = (num / this.totalScans) * 100
    if (percentageAsInt < 15) {
      return 15
    }
    return percentageAsInt
  }

  onCopy () {
    Container.get(Notification).success('Successfully copied link.')
  }

  onError () {
    Container.get(Notification).success('There was an error copying the link. Please refresh and try again.')
  }

  get areaChartData () {
    if (!this.$store.state.leads.leadsStats || !Object.keys((this.$store.state.leads.leadsStats?.total_scans_by_date || {})).length) {
      return [['Date', 'Scans'], [moment().format('MM/DD/YYYY'), 0]]
    }
    const regCounts = this.$store.state.leads.leadsStats.total_scans_by_date
    const regCountsSorted = new Map()
    Object.keys(regCounts).sort((a, b) => (moment(a).toDate() as any) - (moment(b).toDate() as any)).forEach((key) => {
      regCountsSorted.set(key, regCounts[key])
    })
    const areaChart = [['Date', 'Scans']]
    for (const [date, scans] of regCountsSorted) {
      if (date === '' || date === null) {
        areaChart.push([
          date,
          scans
        ])
      } else {
        areaChart.push([
          moment(date).format('MM-DD-YYYY'),
          scans
        ])
      }
    }
    return areaChart
  }

  get ordersUrl () {
    const event = this.$store.state.event.event
    if (event?.event_identifier) {
      return `${process.env.VUE_APP_REG_URL}/${event.event_identifier}/leads`
    }
  }

  async beforeRouteEnter (from, to, next) {
    const response = await Container.get(EventService).getEventModules(from.params.event_uuid)
    if (response.data) {
      const registrationModuleActive = response.data.LEADS.enabled
      if (registrationModuleActive) {
        next()
      } else {
        const message = 'Leads Module not activated. Please, activate it and try again...'
        router.push({ name: 'level-two.event.modules', params: { uuid: from.params.uuid, event_uuid: from.params.event_uuid } }, () => {
          Container.get(Notification).error(message)
        })
      }
    }
    next(false)
  }

  // If there are no scans yet, we alter areaChartOptions to eliminate negatives from the Y axis
  @Watch('areaChartData')
  onAreaChartDataChange (data: any[]) {
    if (data && Array.isArray(data)) {
      if (data.length === 2) {
        if (data[1][0] === moment().format('MM/DD/YYYY') && data[1][1] === 0) {
          this.$data.areaChartOptions.vAxis.viewWindow.min = 0
          this.$data.areaChartOptions.vAxis.viewWindow.max = 1
        }
      }
    }
  }

  @Watch('exhibitorStats')
  onLeadsChange (payload: any) {
    if (payload) {
      this.$data.table.items = payload
      // this.$data.table.items.forEach((item) => {
      //   item.search_data = item.company + ' ' + item.first_name + ' ' + item.last_name + ' ' + item.email
      // })
    }
  }

  async mounted () {
    this.$store.dispatch('email/fetchEmails', this.$route.params.event_uuid)
    const promises: Promise<any>[] = [this.fetchLeads(), this.fetchLeadsStats(), this.fetchExhibitorStats()]
    await Promise.all(promises)
  }

  onClickRowHandle (participant) {
    this.$router.push({
      name: 'level-two.modules.leads.scans.index',
      params: { participant_uuid: participant.uuid }
    })
  }

  private async fetchLeads () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('leads/fetchLeads', { event_uuid: this.$data.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchLeadsStats () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('leads/fetchLeadsStats', { event_uuid: this.$data.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchExhibitorStats () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('leads/fetchExhibitorStats', { event_uuid: this.$data.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async exportAll () {
    try {
      this.$data.loading = true
      const response = await this.$store.dispatch('leads/getExportAll', this.$route.params.event_uuid)
      this.checkURLAndGo(response.data)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async exportPro () {
    try {
      this.$data.loading = true
      const response = await this.$store.dispatch('leads/getExportDevice', { event_uuid: this.$route.params.event_uuid, device: 'ProScanner' })
      this.checkURLAndGo(response.data)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async exportMicro () {
    try {
      this.$data.loading = true
      const response = await this.$store.dispatch('leads/getExportDevice', { event_uuid: this.$route.params.event_uuid, device: 'MicroScanner' })
      this.checkURLAndGo(response.data)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async exportMobile () {
    try {
      this.$data.loading = true
      const response = await this.$store.dispatch('leads/getExportDevice', { event_uuid: this.$route.params.event_uuid, device: 'Lead Retrieval Mobile App' })
      this.checkURLAndGo(response.data)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async exportAttendeeBootsScans () {
    try {
      this.$data.loading = true
      const response = await this.$store.dispatch('leads/getExportAttendeeBoothScans', this.$route.params.event_uuid)
      this.checkURLAndGo(response.data)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async exportAllLeadsSpreadsheets () {
    try {
      this.$data.loading = true
      const response = await this.$store.dispatch('leads/getExportAllLeadsSpreadSheets', this.$route.params.event_uuid)
      this.checkURLAndGo(response.data)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async getExportAllLeadsCounts () {
    try {
      this.$data.loading = true
      const response = await this.$store.dispatch('leads/getExportAllLeadsCounts', this.$route.params.event_uuid)
      this.checkURLAndGo(response.data)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async exportActivationCodesByExhibitor () {
    try {
      this.$data.loading = true
      const response = await this.$store.dispatch('leads/exportActivationCodesByExhibitor', this.$route.params.event_uuid)
      this.checkURLAndGo(response.data)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async sendLeadsEmail (email, uuid) {
    try {
      this.$data.loading = true
      this.$data.to = email
      this.$data.registration_uuid = uuid
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        company_uuid: this.$route.params.event_uuid,
        registration_uuid: this.$data.registration_uuid,
        email_name: this.$data.email_name,
        data: {
          event_uuid: this.$route.params.event_uuid,
          company_uuid: this.$route.params.event_uuid,
          registration_uuid: this.$data.registration_uuid,
          email_name: this.$data.email_name,
          to: this.$data.to != null ? this.$data.to.split(',') : '',
          cc: this.$data.cc != null ? this.$data.cc.split(',') : '',
          bcc: this.$data.bcc != null ? this.$data.bcc.split(',') : ''
        }
      }
      await this.$store.dispatch('email/sendEmailToOneRegistration', payload)
      Container.get(Notification).success('Leads email successfully sent.')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  get leadsEmail () {
    const emails = this.$store.state.email.emails
    let leadsEmail = null
    if (emails) {
      emails.forEach(email => {
        if (email.key === 'your_leads') {
          leadsEmail = email
        }
      })
    }
    return leadsEmail
  }
}
