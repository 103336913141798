import { AccessLevel } from '@/modules/common/enums/access-levels.enum'
import CommonModule from '@/modules/common/index.module'
import GtrStorage from '@/modules/common/services/storage.service'
import LevelOneModule from '@/modules/level-one/index.module'
import LevelTwoModule from '@/modules/level-two/index.module'
import Container from 'typedi'
import Vue from 'vue'
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: RouteConfig[] = [
  ...Container.get(CommonModule).routes(),
  ...Container.get(LevelOneModule).routes(),
  ...Container.get(LevelTwoModule).routes()
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
  const routeToRedirectToForNonAdmins: any = { name: 'level-two.company.dashboard', params: {} }
  const isProtectedRoute = to.matched.some(value => value.meta.requireAuth)
  const routePermissions = to.meta?.permissions // string or array of strings
  const securityContext = JSON.parse(
    Container.get(GtrStorage).getItem('security_context')
  )
  const isLoggedIn = securityContext !== null
  const accessLevel = securityContext?.access_level
  const userGroupPermissions = securityContext?.user?.user_group?.permissions
  const routePermissionsExist = !!(routePermissions && routePermissions.length)
  let hasPermission = false
  if (accessLevel === AccessLevel.SUPER_ADMIN) {
    hasPermission = true
  } else if (Array.isArray(routePermissions)) {
    hasPermission = routePermissions.some(permission => userGroupPermissions?.[permission]) // true if user has any of the required permissions
  } else if (typeof routePermissions === 'string') {
    hasPermission = userGroupPermissions?.[routePermissions] || false
  }

  if (isProtectedRoute) {
    if (!isLoggedIn) {
      Container.get(GtrStorage).setItem('originalUrl', location.href)
      return next({ name: 'security.login' })
    } else {
      if (securityContext && securityContext.user.password_change_required && to.name !== 'security.password_setup') {
        // Container.get(GtrStorage).removeItem('security_context')
        // return next({ name: 'security.login' })
        return next({
          name: 'security.password_setup',
          params: {
            token: securityContext.access_token
          }
        })
      }

      if (routePermissionsExist) {
        if (hasPermission) {
          next()
        } else {
          return next({ name: 'security.login' })
        }
      } else {
        if (to.meta && to.meta.authorize && !to.meta.authorize.includes(accessLevel)) {
          const { user = null, company = null, has_company = false } = securityContext
          if (user && has_company && company) {
            const { uuid } = company
            routeToRedirectToForNonAdmins.params.uuid = uuid
            return next(routeToRedirectToForNonAdmins)
          }
          return next({ name: 'security.login' })
        } else {
          next()
        }
      }
    }
  } else {
    if (securityContext && securityContext.user.password_change_required && to.name !== 'security.password_setup') {
      // Container.get(GtrStorage).removeItem('security_context')
      // return next({ name: 'security.login' })
      return next({
        name: 'security.password_setup',
        params: {
          token: securityContext.access_token
        }
      })
    }
    if ((accessLevel === AccessLevel.SUPER_ADMIN || accessLevel === AccessLevel.RESELLER_ADMIN) && to.name !== 'security.password_setup') {
      return next({ name: 'level-one.dashboard' })
    } else if (accessLevel === AccessLevel.COMPANY_ADMIN || accessLevel === AccessLevel.SELF_SERVICE_ADMIN) {
      if (securityContext.company !== null && to.name !== 'security.password_setup') {
        return next({ name: 'level-two.company.dashboard', params: { uuid: securityContext.company.uuid } })
      }
    }
    next()
  }
})

router.afterEach((to: Route, from: Route) => {
  if (to.meta && to.meta.title) {
    document.title = `${process.env.VUE_APP_NAME} | ${to.meta.title}`
  } else {
    document.title = `${process.env.VUE_APP_NAME}`
  }
})

export default router
