import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Container from 'typedi'
import { Vue, Component } from 'vue-property-decorator'
@Component({
  name: 'GtrCompanyInvoices'
})
export default class GtrCompanyInvoices extends Vue {
  data () {
    return {
      loading: false,
      search: '',
      table: {
        items: [],
        headers: [
          {
            text: 'Date',
            align: 'center',
            sortable: true,
            value: 'date',
            width: '250px'
          },
          {
            text: 'Amount',
            value: 'total',
            align: 'center',
            sortable: true
          },
          {
            text: 'Reference',
            value: 'id',
            align: 'center',
            sortable: false
          },
          {
            text: 'Description',
            value: 'description',
            align: 'start',
            sortable: false
          },
          {
            text: 'Actions',
            value: 'actions',
            searchable: false,
            sortable: false,
            width: '90px'
          }
        ]
      }
    }
  }

  async mounted () {
    try {
      this.$data.loading = true
      if (this.$store.state.security.companyOwnerSubscription) {
        this.$data.table.items = this.$store.state.security.companyOwnerSubscription.invoices
      } else {
        if (!this.$store.state.security.currentUser) {
          await this.$store.dispatch('security/getCurrentUser')
        }
        const response = await this.$store.dispatch('security/getCompanyOwnerSubscription', { owner_uuid: this.$store.state.security.currentUser.subscription_owner_uuid })
        this.$data.table.items = response.data.invoices
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async handleDownloadInvoice (url: string, id: string) {
    try {
      this.$store.dispatch('common/showLoader', { value: true })
      const response = await this.$store.dispatch('invoice/downloadInvoice', { url })
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', `${id}.pdf`)
      document.body.appendChild(link)
      link.click()
      link.remove()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$store.dispatch('common/hideLoader')
    }
  }
}
