import { render, staticRenderFns } from "./settings-fields.vue?vue&type=template&id=924e8d0e&scoped=true&"
import script from "./settings-fields.ts?vue&type=script&lang=ts&"
export * from "./settings-fields.ts?vue&type=script&lang=ts&"
import style0 from "./settings-fields.vue?vue&type=style&index=0&id=924e8d0e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "924e8d0e",
  null
  
)

export default component.exports