import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

@Component({
  name: 'SettingMixin',
  computed: {
    ...mapGetters('option', ['registrationTypes'])
  }
})
export default class SettingMixin extends Vue {
  /**
   * @param registrationTypes {Array<any>} vuex binding for the computed registration types from the option store
   */
  registrationTypes!: Array<any>;

  /**
   * @param registrationTypeToEdit {string}
   */
  registrationTypeToEdit = '_default'

  /**
   * @param originalSettings {any} local state to store a copy of the initial settings recieved.
   */
  originalSettings: any = {}

  /**
   * @param changed {boolean} a toggle to see mark if this setting has been modified.
   */
  changed = false

  /**
   * @prop value {any} The setting that is being modified.
   */
  @Prop({ required: true })
  value!: any;

  /**
   * @optional
   * @prop permissions {string} the permission needed to edit this setting.
   */
  @Prop({ required: false })
  permissions!: string;

  /**
   * @optional
   * @prop defaultRegistrationOnly {boolean} use only the default registration type
   * @readonly
   */
  @Prop({ required: false, type: Boolean, default: false })
  readonly defaultRegistrationOnly!: boolean;

  /**
   * @optional
   * @prop default: {unknown} the default value of a setting.
   * @readonly
   */
  @Prop({ required: false })
  readonly default!: unknown;

  /**
   * @property regTypesFiltered returns a list of registration types. Returns default if defaultRegistrationOnly is true
   * @returns array
   */
  get regTypesFiltered () {
    return this.defaultRegistrationOnly ? this.registrationTypes.filter(type => type.value === '_default') : this.registrationTypes
  }

  /**
   * @property hasPermission computed property that will return if use has permissions. Fallback is true.
   * @returns boolean
   */
  get hasPermission () {
    if (this.permissions) {
      return this.$hasPermission(this.permissions)
    } else {
      return true
    }
  }

  /**
   * getter for setting the computed property of the modeled setting.
   */
  get setting () {
    return this.value
  }

  /**
   * setter for setting. Emits the input event with the updated settings.
   * @param value
   */
  set setting (value: any) {
    this.$emit('input', value)
  }

  /**
   * regTypeHasSetting - checks if a registration type uuid is in the setting.
   * @param uuid - the uuid to look for.
   * @returns boolean
   */
  regTypeHasSetting (uuid: string) {
    return (uuid in this.setting)
  }

  /**
   * useDefaultSetting deletes the setting for a given reg type then passes the updated settings to the parent.
   * @param uuid {string}
   */
  useDefaultSetting (uuid: string) {
    const copy = structuredClone(this.setting)
    delete copy[uuid]
    this.$emit('input', copy)
  }

  /**
   * overrideDefaultSetting addes the given reg type to the settings value and initializes it to the provided default. Fallback is false.
   * TODO(zb): will need to change the fallback for different input types.
   * @param uuid {string}
   */
  overrideDefaultSetting (uuid: string) {
    const copy = structuredClone(this.setting)
    copy[uuid] = this.default ?? false
    this.$emit('input', copy)
  }

  /**
   * revertSetting restores the initial setting and sets changed to false.
   */
  revertSetting () {
    this.changed = false
    this.$emit('input', this.originalSettings)
  }
}
