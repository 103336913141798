import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Container from 'typedi'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import GtrCompanyInvoices from './tab-components/invoices/invoices.vue'
import GtrCompanyPaymentMethods from './tab-components/payment-methods/payment-methods.vue'

@Component({
  name: 'GtrCompanyInfoView',
  computed: {
    ...mapState('company', ['company']),
    ...mapState('event', ['events'])
  },
  components: {
    'gtr-company-invoices': GtrCompanyInvoices,
    'gtr-company-payment-methods': GtrCompanyPaymentMethods
  }
})
export default class GtrCompanyInfoView extends Vue {
  events!: Array<Record<string, any>>

  company!: Record<string, any>

  data () {
    return {
      loading: false,
      tab: 0
    }
  }

  @Watch('company', { immediate: true, deep: true })
  async onCompanyChange (payload: any) {
    if (payload) {
      try {
        this.$data.loading = true
        await this.$store.dispatch('event/loadEventsForCompany', { company_uuid: this.$route.params.uuid })
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.loading = false
      }
    }
  }

  get companyEvents (): any[] {
    if (this.events.length) {
      const { uuid } = this.$route.params
      return this.events.filter((c: Record<string, any>) => (c.uuid === uuid))[0].events ?? []
    }
    return []
  }

  get participantCount (): number {
    let count = 0
    for (let i = 0; i < this.companyEvents.length; i++) {
      count += this.companyEvents[i].stats.participant_counts
    }
    return count
  }

  get adminUserCount (): number {
    return this.company?.admin_users.length
  }

  getUpcomingEvents (payload: any) {
    if (payload && payload.future_events && payload.future_events.length > 0) {
      return `${payload.future_events[0].name}`
    } else {
      return 'N/A'
    }
  }
}
