import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import Container from 'typedi'
import AttendeeService from '../services/attendee.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import { AxiosResponse } from 'axios'

@Module({
  namespaced: true
})
export default class AttendeeStore extends VuexModule {
  attendees: any[] = []
  attendeesWithReferralCode: any[] = []
  attendeesListViewWithCustomField: any[] = []
  attendeesWithPromoCode: any[] = []
  attendeesWithOptionSelection: any[] = []
  attendee_fees: any[] = []
  attendee: any = null
  deleted_attendees: any[] = []
  attendee_activity_logs: any[] = []

  get getAttendees () {
    return this.attendees
  }

  get totalAttendees () {
    return ((this.attendees as Record<string, any>).data || []).length
  }

  @Mutation
  SET_ATTENDEES (payload: any) {
    this.attendees = payload
  }

  @Mutation
  SET_ATTENDEES_WITH_REFERRAL_CODE (payload: any) {
    this.attendeesWithReferralCode = payload
  }

  @Mutation
  SET_ATTENDEES_LIST_VIEW_WITH_CUSTOM_FIELD (payload: any) {
    this.attendeesListViewWithCustomField = payload
  }

  @Mutation
  SET_ATTENDEES_WITH_PROMO_CODE (payload: any) {
    this.attendeesWithPromoCode = payload
  }

  @Mutation
  SET_ATTENDEES_WITH_OPTION_SELECTION (payload: any) {
    this.attendeesWithOptionSelection = payload
  }

  @Mutation
  SET_ATTENDEE (payload: any) {
    this.attendee = payload
  }

  @Mutation
  APPEND_ATTENDEES (payload: any[]): void {
    const uuids = this.attendees.map(attendee => attendee.uuid)
    try {
      for (let i = 0; i < payload.length; i++) {
        if (uuids.includes(payload[i].uuid)) continue
        this.attendees.push(payload[i])
      }
    } catch (e) {
      Container.get(Notification).error(e.message)
    }
  }

  @Mutation
  SET_ATTENDEE_FEES (payload: any) {
    this.attendee_fees = payload
  }

  @Mutation
  SET_DELETED_ATTENDEES (payload: any) {
    this.deleted_attendees = payload
  }

  @Mutation
  SET_ATTENDEE_ACTIVITY_LOGS (payload: any) {
    this.attendee_activity_logs = payload
  }

  @Action({ rawError: true })
  async fetchAttendees (event_uuid: string) {
    const response = await Container.get(AttendeeService).getAttendees(event_uuid)
    this.context.commit('SET_ATTENDEES', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async fetchRandomAttendee (payload: any) {
    try {
      const { data: attendees } = await Container.get(AttendeeService).getAttendees(payload.event_uuid)
      if (attendees.data.length < 1) {
        throw new Error('No attendees have registered.')
      }
      const attendeesByRegType = attendees.data.filter(attendee => attendee.registration_type === payload.reg_type)
      const attendeesByDefault = attendees.data.filter(attendee => !attendee.registration_type)
      let attendeePool
      if (payload.reg_type !== '_default' && attendeesByRegType?.length) {
        attendeePool = attendeesByRegType
      } else if (payload.reg_type === '_default') {
        if (attendeesByDefault?.length) {
          attendeePool = attendeesByDefault
        } else {
          attendeePool = attendees.data
        }
      }
      // select random participant.
      return attendeePool?.[Math.floor(Math.random() * (attendeePool?.length - 1))]
    } catch (e) {
      if (e instanceof Error) {
        Container.get(Notification).error(e.message)
      }
    }
  }

  @Action({ rawError: true })
  async fetchAttendeesListView (payload: any) {
    let { event_uuid, limit, listViewFields } = payload
    if (!limit) {
      limit = 500
    }
    const service = Container.get(AttendeeService)
    let response: AxiosResponse<any, any>
    let fetching = true
    let skip = 0
    this.context.commit('SET_ATTENDEES', [])
    while (fetching) {
      response = await service.getAttendeesListView(event_uuid, {
        limit,
        skip,
        listViewFields
      })
      if (response.data.length === 0) {
        fetching = false
      } else {
        this.context.commit('APPEND_ATTENDEES', response.data)
        skip += limit
      }
    }
    return this.attendees
  }

  @Action({ rawError: true })
  async fetchAttendeesListViewByStatus (payload: any) {
    const service = Container.get(AttendeeService)
    let response: AxiosResponse<any, any>
    let fetching = true
    let skip = 0
    this.context.commit('SET_ATTENDEES', [])
    const { event_uuid, limit, status } = payload
    while (fetching) {
      response = await service.getAttendeesListView(event_uuid, { limit, skip, status })
      if (response.data.length === 0) {
        fetching = false
      } else {
        this.context.commit('APPEND_ATTENDEES', response.data)
        skip += limit
      }
    }
    return this.attendees
  }

  @Action({ rawError: true })
  async fetchAttendeesListViewDirect (payload: any) {
    const { event_uuid, limit, skip, listViewFields, field, value, meta, requiredFields } = payload
    const service = Container.get(AttendeeService)
    payload = {
      limit,
      skip,
      listViewFields,
      meta,
      requiredFields
    }
    if (field) {
      payload.customField = { field, value }
    }
    return await service.getAttendeesListView(event_uuid, payload)
  }

  @Action({ rawError: true })
  async fetchAttendeesListViewByStatusDirect (payload: any) {
    const { event_uuid, limit, skip, status } = payload
    const service = Container.get(AttendeeService)
    const response = await service.getAttendeesListView(event_uuid, {
      limit,
      skip,
      status
    })
    return response.data
  }

  @Action({ rawError: true })
  async exportParticipantScans (event_uuid: any) {
    return await Container.get(AttendeeService).exportParticipantScans(event_uuid)
  }

  @Action({ rawError: true })
  async fetchAttendeesWithReferralCode (payload: any) {
    const { event_uuid, referral_code, status, statusName } = payload
    const response = await Container.get(AttendeeService).getAttendeesListView(event_uuid, {
      referral_code,
      status,
      statusName
    })
    this.context.commit('SET_ATTENDEES_WITH_REFERRAL_CODE', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async fetchAttendeesListViewWithCustomField (payload: any) {
    const { event_uuid, field, value } = payload
    const response = await Container.get(AttendeeService).getAttendeesListView(event_uuid, {
      customField: { field, value }
    })
    this.context.commit('SET_ATTENDEES_LIST_VIEW_WITH_CUSTOM_FIELD', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async fetchAttendeesWithPromoCode (payload: any) {
    const { event_uuid, promo_code } = payload
    const response = await Container.get(AttendeeService).getAttendeesListView(event_uuid, {
      promo_code
    })
    this.context.commit('SET_ATTENDEES_WITH_PROMO_CODE', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async fetchAttendeesWithOptionSelection (payload: any) {
    const { event_uuid, option_group_uuid, option_uuid, status } = payload
    const response = await Container.get(AttendeeService).getAttendeesListView(event_uuid, {
      optionSelection: {
        option_group_uuid,
        option_uuid,
        status
      }
    })
    this.context.commit('SET_ATTENDEES_WITH_OPTION_SELECTION', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async addAttendee (payload: any) {
    const { event_uuid, data } = payload
    const response = await Container.get(AttendeeService).addAttendee(event_uuid, data)
    return response.data
  }

  @Action({ rawError: true })
  async fetchAttendee (payload: any) {
    const response: any = await Container.get(AttendeeService).getAttendee(payload)
    this.context.commit('SET_ATTENDEE', response.data)
    return response
  }

  @Action({ rawError: true })
  async fetchAttendeeFees (payload: any) {
    const response: any = await Container.get(AttendeeService).getAttendeeFees(payload)
    this.context.commit('SET_ATTENDEE_FEES', response.data)
    return response
  }

  @Action({ rawError: true })
  async fetchDeletedAttendees (payload: any) {
    const response: any = await Container.get(AttendeeService).getDeletedAttendees(payload)
    this.context.commit('SET_DELETED_ATTENDEES', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async fetchAttendeeActivityLogs (payload: any) {
    const response: any = await Container.get(AttendeeService).getAttendeeActivityLog(payload)
    this.context.commit('SET_ATTENDEE_ACTIVITY_LOGS', response.data)
    return response
  }

  @Action({ rawError: true })
  async deleteAttendees (payload: any) {
    const response = await Container.get(AttendeeService).deleteAttendees(payload)
    return response.data
  }

  @Action({ rawError: true })
  async searchAttendees (payload: any) {
    const response: any = await Container.get(AttendeeService).searchAttendees(payload)
    return response.data
  }
}
