import { AccessLevel } from '@/modules/common/enums/access-levels.enum'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import GtrStorage from '@/modules/common/services/storage.service'
import Container from 'typedi'
import { Component, Vue } from 'vue-property-decorator'
import GrtSecurityLayout from '../../layouts/security/security.layout.vue'

@Component({
  name: 'GtrPasswordSetupView'
})
export default class GtrPasswordSetupView extends Vue {
  data () {
    return {
      submitting: false,
      model: {
        password: null,
        password_confirmation: null
      }
    }
  }

  created () {
    this.$emit('update:layout', GrtSecurityLayout)
  }

  async submit () {
    try {
      this.$data.submitting = true
      let securityContext = Container.get(GtrStorage).getItem('security_context')
      if (securityContext) {
        securityContext = JSON.parse(securityContext)
        const accessLevel = securityContext.access_level
        await this.$store.dispatch('security/updateUser', {
          user_uuid: securityContext.user.uuid,
          data: {
            password: this.$data.model.password
          }
        })
        securityContext.user.password_change_required = false
        Container.get(GtrStorage).setItem('security_context', JSON.stringify(securityContext))
        if (accessLevel === AccessLevel.SUPER_ADMIN || accessLevel === AccessLevel.RESELLER_ADMIN) {
          this.$router.push({ name: 'level-one.dashboard' }, () => {
            Container.get(Notification).success('Setup password successfully.')
          })
        } else if (accessLevel === AccessLevel.COMPANY_ADMIN || accessLevel === AccessLevel.SELF_SERVICE_ADMIN) {
          if (securityContext.company !== null) {
            this.$router.push({ name: 'level-two.company.dashboard', params: { uuid: securityContext.company.uuid } }, () => {
              Container.get(Notification).success('Setup password successfully.')
            })
          } else {
            this.$router.push({ name: 'level-two.company.register' }, () => {
              Container.get(Notification).success('Setup password successfully.')
            })
          }
        } else {
          this.$router.push({ name: 'security.login' }, () => {
            Container.get(Notification).success('Setup password successfully.')
          })
        }
      } else {
        this.$router.push({ name: 'security.login' })
      }
    } catch (error) {
      Container.get(GtrStorage).removeItem('security_context')
      Container.get(ErrorHandlerService).error(error)
      this.$router.push({ name: 'security.login' })
    } finally {
      this.$data.submitting = false
    }
  }
}
