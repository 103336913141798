import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Vue, Component } from 'vue-property-decorator'

@Component({
  name: 'GtrCompanyPaymentMethods'
})
export default class GtrCompanyPaymentMethods extends Vue {
  data () {
    return {
      loading: false,
      loadingIntent: false,
      card: {
        cvc: false,
        number: false,
        expiry: false
      },
      elements: {
        cardNumber: '',
        cardExpiry: '',
        cardCvc: '',
        stripe: null
      },
      errors: {
        stripeError: '',
        cardCvcError: '',
        cardExpiryError: '',
        cardNumberError: ''
      },
      stripeAPIToken: 'pk_test_XXX',
      paymentMethods: [],
      addingPaymentMethod: false,
      newPaymentMethod: {
        holder: null,
        cardNumber: null,
        expiry: null,
        cvc: null
      },
      intentToken: '',
      paymentMethodToDelete: null
    }
  }

  get isValid () {
    return this.$data.newPaymentMethod.holder !== null && this.$data.card.number !== false && this.$data.card.cvc !== false && this.$data.card.expiry !== false
  }

  async beforeCreate () {
    if (!(window as any).Stripe) {
      const stripeScript: HTMLScriptElement = document.createElement('script')
      stripeScript.setAttribute('src', 'https://js.stripe.com/v3/')
      document.head.appendChild(stripeScript)
    }
  }

  async mounted () {
    try {
      this.$data.loading = true
      await this.loadPaymentMethods()
    } catch (error) {
      Container.get(Notification).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async setupStripe () {
    await this.sleep(2000)
    if (!(window as any).Stripe) {
      Container.get(Notification).warning('Stripe library not loaded.')
    }
  }

  async addPaymentMethodTemplate () {
    if (this.$data.addingPaymentMethod) {
      Container.get(Notification).warning('Complete the payment method please')
      return
    }
    this.$data.loadingIntent = true
    await this.loadIntent()
    const style = {
      base: {
        fontSize: '16px',
        '::placeholder': {
          color: '#00000099'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    }
    const stripe = (window as any).Stripe(this.$data.stripeAPIToken)
    this.$data.elements.stripe = stripe
    const elements = stripe.elements()
    this.$data.elements.cardCvc = elements.create('cardCvc', { style })
    this.$data.elements.cardExpiry = elements.create('cardExpiry', { style })
    this.$data.elements.cardNumber = elements.create('cardNumber', { style, showIcon: true, placeholder: 'Card Number' })
    this.$data.elements.cardCvc.mount('#card-cvc')
    this.$data.elements.cardExpiry.mount('#card-expiry')
    this.$data.elements.cardNumber.mount('#card-number')
    this.listenForErrors()
    this.$data.addingPaymentMethod = true
    this.$data.loadingIntent = false
  }

  removePaymentMethodTemplate () {
    this.$data.addingPaymentMethod = false
    this.clearForm()
  }

  onDeletePaymentMethod (payload: any) {
    this.$data.paymentMethodToDelete = payload
  }

  async savePaymentMethod () {
    let valid = true
    if (!this.$data.card.number) {
      valid = false
    }
    if (!this.$data.card.cvc) {
      valid = false
    }
    if (!this.$data.card.expiry) {
      valid = false
    }
    if (valid) {
      try {
        this.$store.dispatch('common/showLoader', { value: true })
        const response: any = await this.$data.elements.stripe.confirmCardSetup(this.$data.intentToken.client_secret, {
          payment_method: {
            card: this.$data.elements.cardNumber,
            billing_details: {
              name: this.$data.newPaymentMethod.holder
            }
          }
        })
        if (response.error) {
          Container.get(ErrorHandlerService).error(response.error)
        } else {
          await this.submitPaymentMethod(response.setupIntent.payment_method)
          this.clearForm()
          this.$data.addingPaymentMethod = false
          Container.get(Notification).success('Payment method successfully saved.')
        }
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$store.dispatch('common/hideLoader')
      }
    }
  }

  async submitPaymentMethod (payment_method: any) {
    try {
      await this.$store.dispatch('payment/savePaymentMethod', payment_method)
      await this.loadPaymentMethods()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  async handleDeletePaymentMethodAction (payload: any) {
    if (payload.confirm) {
      try {
        this.$store.dispatch('common/showLoader', { value: true })
        await this.$store.dispatch('payment/removePaymentMethod', { id: this.$data.paymentMethodToDelete.id })
        await this.loadPaymentMethods()
        Container.get(Notification).success('Payment method successfully deleted.')
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$store.dispatch('common/hideLoader')
      }
    }
    this.$data.paymentMethodToDelete = null
  }

  getLastDigitsOfYear (year: any) {
    return String(year).substring(4, 2)
  }

  private sleep (ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  private clearForm () {
    this.$data.elements.cardNumber.clear()
    this.$data.elements.cardExpiry.clear()
    this.$data.elements.cardCvc.clear()
    this.$data.newPaymentMethod.holder = null
  }

  private async loadIntent () {
    try {
      const response = await this.$store.dispatch('payment/loadIntent')
      if (response.data) {
        this.$data.intentToken = response.data
        this.$data.stripeAPIToken = response.data.stripe_key
        this.setupStripe()
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async loadPaymentMethods () {
    try {
      const response = await this.$store.dispatch('payment/loadPaymentMethods')
      if (response.data) {
        this.$data.paymentMethods = response.data
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  async handleSetAsDefault (paymentMethod: any) {
    try {
      this.$store.dispatch('common/showLoader', { value: true })
      await this.$store.dispatch('payment/setAsDefault', { payment_id: paymentMethod.id })
      await this.loadPaymentMethods()
      Container.get(Notification).success('The default payment method was changed successfully.')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$store.dispatch('common/hideLoader')
    }
  }

  private listenForErrors () {
    this.$data.elements.cardNumber.addEventListener('change', (event: any) => {
      this.toggleError(event)
      this.$data.card.number = !!event.complete
    })

    this.$data.elements.cardExpiry.addEventListener('change', (event) => {
      this.toggleError(event)
      this.$data.card.expiry = !!event.complete
    })

    this.$data.elements.cardCvc.addEventListener('change', (event) => {
      this.toggleError(event)
      this.$data.card.cvc = !!event.complete
    })
  }

  private toggleError (event: any) {
    const errorMessages = {
      incomplete_number: 'The card number is incomplete.',
      invalid_number: 'The card number is not a valid credit card number.',
      invalid_expiry_month: "The card's expiration month is invalid.",
      invalid_expiry_year: "The card's expiration year is invalid.",
      incomplete_expiry: 'The expiration date of your card is incomplete.',
      invalid_cvc: "The card's security code is invalid.",
      expired_card: 'The card has expired.',
      incorrect_cvc: "The card's security code is incorrect.",
      incorrect_zip: "The card's zip code failed validation.",
      card_declined: 'The card was declined.',
      missing: 'There is no card on a customer that is being charged.',
      processing_error: 'An error occurred while processing the card.',
      rate_limit: "An error occurred due to requests hitting the API too quickly. Please let us know if you're consistently running into this error.",
      incomplete_cvc: 'The security code for your card is incomplete.',
      invalid_expiry_year_past: "The card's expiration year is invalid."
    }
    if (event.error) {
      this.$data.errors.stripeError = errorMessages[event.error.code]
    } else {
      this.$data.errors.stripeError = ''
    }
  }
}
