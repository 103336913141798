import {
  GroupParticipantOptionSelections,
  GroupParticipantFormAnswers,
  EagerTextRules,
  IndexToUuidMap,
  SubmissionMap,
  FieldOrPage,
  SetupInfo,
  ShowIf
} from '@/modules/common/components/ui-core/gtr-group-registration/interfaces/index.interfaces'
import GtrSessionField from '@/modules/common/components/ui-core/gtr-session-field/gtr-session-field.vue'
import { Session } from '@/modules/common/interfaces/registration/index.interface'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import ShowIfMixin from '@/modules/common/components/mixins/showif.mixin'
import { Component, Watch } from 'vue-property-decorator'
import { Container } from 'typedi'
import { mapState } from 'vuex'

type SingleOrGroup = 'single' | 'group'

/**
 * TODO: Build an object to store the values on the form.
 * TODO: Add a path to save the form.
 */
@Component({
  name: 'GtrGroupRegistration',
  components: {
    'gtr-session-field': GtrSessionField
  },
  computed: {
    ...mapState('formbuilder', ['form']),
    ...mapState('event', ['eventAllContent'])
  }
})
export default class GtrGroupRegistration extends ShowIfMixin {
  form!: Record<string, any>;
  eventAllContent!: Record<string, any>;

  // Data
  page = 0;

  // This is the basics of what I need to know
  setupInfo: SetupInfo = {
    totalParticipants: 1,
    email: ''
  }

  participantCounter = 0

  useParentAnswers = false

  processMessage = ''

  // The uuid of this group.
  groupUuid = ''

  // The index of the selected participant
  selectedParticipant = ''

  groupParentUuid = ''

  // The list of participant form responses.
  groupParticipantEmails: string[] = []

  groupParticipantAnswers: Record<string, GroupParticipantFormAnswers> = {}

  // The list of participant option selections.
  groupParticipantsOptionSelections: Record<string, GroupParticipantOptionSelections> = {}

  // A map of the page index to a participant. Provides O(1) access (on average)
  participantIndexToUuidMap: IndexToUuidMap = {}

  // Similar to above. Track if the participant has been submitted.
  participantSubmissionMap: SubmissionMap = {}

  // Local state to hold the participants option selections a user is currently edit
  currentParticipantOptionSelections: GroupParticipantOptionSelections = {}

  // Local state to hold the current participants form responses.
  currentParticipant: Record<string, any> = {}

  processing = false

  addingParticipant = false

  deletingParticipant = false

  participantToBeDeleted = ''

  setupCompleted = false

  registrationType: SingleOrGroup = 'group'

  singleOrGroupOptions = [
    {
      label: 'Single',
      value: 'single'
    },
    {
      label: 'Group',
      value: 'group'
    }
  ]

  readonly requiredFields = ['first_name', 'last_name', 'email']

  // Set the current participant you are editing.
  setParticipant (uuid) {
    this.currentParticipant = this.groupParticipantAnswers[uuid]
    this.currentParticipantOptionSelections = this.groupParticipantsOptionSelections[uuid]
    this.selectedParticipant = uuid
  }

  get singleOrGroup () {
    return this.registrationType
  }

  set singleOrGroup (value: SingleOrGroup) {
    if (value === 'group') {
      this.$emit('update:type', 'New Group Registration')
    } else {
      this.$emit('update:type', 'New Registration')
    }
    this.registrationType = value
  }

  // silly. I have to have a getter for this.
  get submitMap () {
    return this.participantSubmissionMap
  }

  get event () {
    return this.eventAllContent.event
  }

  get sessions (): Session[] {
    return (this.optionGroups as Array<any>)?.find(group => group.name === 'Sessions')?.options.filter(option => this.isOptionVisible(option)) ?? []
  }

  // Get the settings for the event.
  get settings () {
    return this.eventAllContent.settings
  }

  get prepopulatedFields (): Array<string> {
    return this.settings.group_registration_prepoulated_fields
  }

  // A map of each option group (uuid) and their options.
  get optionGroupOptions () {
    const options = {} // An object will have O(1) look up.
    for (const group of this.optionGroups) {
      options[group.uuid] = group.options
      if (group.blank_first === 1) {
        options[group.uuid].push({
          label: '',
          uuid: ''
        })
      }
    }
    return options
  }

  // Doesn't allow clicking the previous button if it's the first page.
  get disablePreviousButton () {
    if (this.page === 0) return true
  }

  get disableNextButton () {
    if (this.page === 1 && !this.setupInfoCompleted) return true
    return (this.page === 3)
  }

  // Is the setup info completed?
  get setupInfoCompleted () {
    return Object.values(this.setupInfo).every(value => !!value)
  }

  // All the fields tied to option groups.
  get fieldsAssociatedWithOptionGroups () {
    return this.allFormFields.filter(field => field.option_group_uuid) ?? []
  }

  // This is an object to track the selections of the option groups on the form. This is not used directly.
  get optionGroupsOnForm () {
    const optionGroupUuidsOnForm = this.fieldsAssociatedWithOptionGroups.map(field => field.option_group_uuid)
    const options = {}
    for (const uuid of optionGroupUuidsOnForm) {
      options[uuid] = {}
      for (const option of this.optionGroupOptions[uuid]) {
        options[uuid][option.uuid] = {
          currentValue: 0,
          newValue: 0
        }
      }
    }
    return options
  }

  // Since this is a niche form for group reg, I don't need the group registration page or the receipt.
  // I'm assuming payments will be done elsewhere.
  get formPages () {
    const filterPages = (page) => {
      const { settings } = page
      // don't include group reg page or receipt page.
      return (!['Receipt', 'Group Registration'].includes(settings.name))
    }

    return this.form.page_data?.filter(filterPages) ?? []
  }

  // A list of all fields on the form.
  get allFormFields (): Array<any> {
    return this.formPages?.map(page => page.fields).flat() ?? []
  }

  get eagerTextCustomRule (): EagerTextRules {
    return {
      on: ['change', 'blur', 'input']
    }
  }

  // This gets all the fields on the form.
  get formFields () {
    const fields = {}
    for (const page of this.formPages) {
      for (const field of page.fields) {
        fields[field.field] = field.type === 'checkbox' ? [] : null
      }
    }
    return fields
  }

  // Props

  // Methods
  async mounted () {
    // the form can change so I will refetch each time this mounts.
    const event_uuid = this.$route.params.event_uuid
    await this.$store.dispatch('formbuilder/getForm', { event_uuid })
  }

  getMask (mask: string, currentValue: string) {
    if (mask === 'alphabeticMask') {
      return (currentValue || '').split('').map((_) => /[A-Za-z]/)
    }
    if (mask === 'numericMask') {
      return (currentValue || '').split('').map((_) => /[0-9]/)
    }
    return mask
  }

  isReadOnlyField (field: any) {
    if (field.read_only_if_complete) {
      return false
    }
    if (field.read_only_if_set) {
      return false
    }
    return false
  }

  replaceMask (mask: string) {
    if (['numericMask', 'alphabeticMask'].includes(mask)) {
      return ''
    } else if (mask) {
      return mask.replace(/#/g, ' _')
    }
    return mask
  }

  private getAdjacentKey (next = true): string | null {
    const keys = Object.keys(this.groupParticipantAnswers)
    const currentParticipantUuid = this.selectedParticipant
    const currentIndex = keys.indexOf(currentParticipantUuid)
    if (next) {
      return (currentIndex === -1) ? null : keys[currentIndex + 1]
    } else {
      return (currentIndex === -1) ? null : keys[currentIndex - 1]
    }
  }

  async submitPage () {
    this.processing = true
    // collect the fields (not option groups)
    // the keys to the currentParticipant are the field names.
    const fields = Object.keys(this.currentParticipant)
    const fieldsWithoutOptionGroup = this.allFormFields.filter(field => !field.option_group_uuid).filter(field => fields.includes(field.field)).map(field => field.field)
    const data = {}

    // const splitEmail = this.currentParticipant.email.split('@')

    for (const field of fieldsWithoutOptionGroup) {
      data[field] = this.currentParticipant[field]
    }

    const event_uuid = this.$route.params.event_uuid
    const registration_uuid = this.selectedParticipant

    const payload = {
      event_uuid,
      registration_uuid,
      send_email_override: true,
      skip_fetches: true,
      data
    }

    try {
      for (const group in this.currentParticipantOptionSelections) {
        for (const option in this.currentParticipantOptionSelections[group]) {
          const { currentValue, newValue } = this.currentParticipantOptionSelections[group][option]
          if (currentValue === newValue) continue // no change so don't do anything.
          const payload: Record<string, any> = {
            event_uuid,
            participant_uuid: registration_uuid,
            option_group_uuid: group,
            option_uuid: option,
            removeOtherSelections: false
          }
          if (currentValue < newValue) { // new value is 1, so add a quantity.
            payload.qty = 1
          } else { // new value is 0, so remove a quantity
            payload.qty = -1
          }
          await this.$store.dispatch('registration/updateOptionQty', payload)
          this.currentParticipantOptionSelections[group][option].currentValue = newValue
        }
      }

      await this.$store.dispatch('registration/editRegistration', payload)
      this.participantSubmissionMap[registration_uuid] = true
      Container.get(Notification).success('Participant data saved')

      // we have successfully submitted the parent. Now lets check if we need to map their answers to the children.
      if (registration_uuid === this.groupParentUuid && this.prepopulatedFields.length > 0) {
        this.useParentAnswers = true // this will trigger the watcher for this property.
      }

      const nextParticipant = this.getAdjacentKey()
      if (nextParticipant) {
        this.setParticipant(nextParticipant)
      } else {
        // if we get null that means we finished. Go to next page.
        this.page++
      }
    } catch (error) {
      // if there is an error make sure we mark this participant as not submitted.
      this.participantSubmissionMap[registration_uuid] = false
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.processing = false
    }
  }

  // TODO show if validators belong in in showif mixin but have dependencies from this component. Must fix.
  validateShowIfPage (criteria: ShowIf) {
    const checkCriteria = ({ field: target, type, operator, value }): boolean => {
      if (type === 'option_criteria') {
        const fieldOnForm = this.formPages?.map(page => page.fields).flat().find(field => field.option_group_uuid === target)?.field ?? null
        if (!fieldOnForm) {
          return false
        }
        const userValue = this.currentParticipant[fieldOnForm]
        return this.operatorFunctions[operator](userValue, value)
      } else {
        const userValue = this.currentParticipant[target]
        return this.operatorFunctions[operator](userValue, value)
      }
    }

    let show: boolean

    if (Array.isArray(criteria) && criteria.length === 0) {
      return true
    }

    if (criteria.global_type === 'single') {
      show = checkCriteria(criteria)
    } else {
      const { group_operator, group_items: groups } = criteria
      const results = groups.map(({ group_operator, group_items }) => group_operator === 'AND' ? group_items.every(checkCriteria) : group_items.some(checkCriteria))
      show = group_operator === 'AND' ? results.every(result => result) : results.some(result => result)
    }

    return show
  }

  // TODO add support for session criteria.
  // TODO generalize to handle any form storm (like is it a participant or a parent)
  validateShowIf (valueOfTarget: string | Array<string>, criteria: ShowIf, index: number, field: FieldOrPage, groupUuid: string | null = null): boolean {
    if (field.visible === undefined) {
      field.visible = true
    }

    const checkCriteria = ({ field: target, type, operator, value }): boolean => {
      if (type === 'option_criteria') {
        const fieldOnForm = this.formPages[index].fields.find(field => field.option_group_uuid === target)?.field
        if (!fieldOnForm) {
          return false
        }
        const userValue = this.currentParticipant[fieldOnForm]
        return this.operatorFunctions[operator](userValue, value)
      } else {
        const userValue = this.currentParticipant[target]
        return this.operatorFunctions[operator](userValue, value)
      }
    }

    if (field.visible === false) {
      field.required = false
      return false
    }

    if (Array.isArray(criteria) && criteria.length === 0) {
      return (field.visible)
    }

    // if this has an optionUuid
    if (groupUuid) {
      const group = this.optionGroups.find(({ uuid }) => (uuid === groupUuid))
      if (group && !group.group_display) {
        field.visible = false
        field.required = false
        return false
      }
    }

    let show: boolean

    if (criteria.global_type === 'single') {
      show = checkCriteria(criteria)
    } else {
      const { group_operator, group_items: groups } = criteria
      const results = groups.map(({ group_operator, group_items }) => group_operator === 'AND' ? group_items.every(checkCriteria) : group_items.some(checkCriteria))
      show = group_operator === 'AND' ? results.every(result => result) : results.some(result => result)
    }

    return show
  }

  isOptionVisible (option): boolean {
    const { visible } = option
    return visible
  }

  isParticipantFormCompleted (index, submissionMap) {
    const participantUuid = this.participantIndexToUuidMap[index]
    return submissionMap[participantUuid]
  }

  // This is to handle option selections that can be multiple selections (i.e. checkboxes)
  updateValue (value, field) {
    let index
    if (Array.isArray(this.currentParticipant[field])) {
      index = this.currentParticipant[field].indexOf(value)
    }
    if (this.currentParticipant[field] === null) {
      this.currentParticipant[field] = [value]
    } else if (this.currentParticipant[field].length === 0 || index === -1) {
      this.currentParticipant[field].push(value)
    } else {
      this.currentParticipant[field] = this.currentParticipant[field].filter(option => option !== value)
    }
  }

  generateRulesForField (field: any) {
    const rules: any = {}
    if (this.requiredFields.indexOf(field.field) !== -1) {
      rules.required = true
    }
    const { field: fieldName = '' } = field
    if (fieldName === 'email') {
      rules.email = true
    }
    return rules
  }

  getValidationMode (field) {
    if (['phone_number', 'zip_code'].includes(field.field)) {
      return 'lazy'
    } else {
      return this.eagerTextCustomRule
    }
  }

  previousPage (): void {
    if (this.page !== 0) {
      this.page--
    }
  }

  nextPage (): void {
    if (this.page !== 2) {
      this.page++
    }
  }

  // This currently just closes the modal.
  async cancelGroupRegistration (): Promise<void> {
    const uuids = Object.keys(this.groupParticipantAnswers).reverse()
    if (uuids.length > 0) {
      // because an object respects insertion order, you have to delete the participants in reverse order. Otherwise
      // the parent gets deleted first and this will cause errors.
      for (const uuid of uuids) {
        await this.$store.dispatch('registration/deleteRegistration', {
          event_uuid: this.$route.params.event_uuid,
          registration_uuid: uuid,
          data: {
            company_uuid: this.$route.params.company_uuid,
            deletion_reason: 'TODO'
          }
        })
      }
    }
    this.resetState()
    this.$emit('cancel')
  }

  private resetState (): void {
    this.setupInfo = {
      totalParticipants: 0,
      email: ''
    }
    this.participantCounter = 0
    this.setupCompleted = false
    this.groupUuid = ''
    this.selectedParticipant = ''
    this.page = 0
    this.groupParticipantEmails = []
    this.groupParticipantAnswers = {}
    this.groupParticipantsOptionSelections = {}
    this.participantIndexToUuidMap = {}
    this.participantSubmissionMap = {}
    this.currentParticipantOptionSelections = {}
    this.currentParticipant = {}
  }

  cleanUpAndFinish (): void {
    const parentUuid = this.groupParentUuid
    this.resetState()
    this.$emit('complete', {
      parentUuid
    })
  }

  // Handler to create a participant for each email.
  async createParticipant (email): Promise<any> {
    try {
      const event_uuid = this.$route.params.event_uuid
      const data = {
        email
      }
      return this.$store.dispatch('attendee/addAttendee', {
        event_uuid,
        data
      })
    } catch (error) {
      await Container.get(ErrorHandlerService).error(error)
    }
  }

  async createGroupRegistration (): Promise<any> {
    try {
      const event_uuid = this.$route.params.event_uuid
      const result = await this.$store.dispatch('registration/createGroupRegistration', event_uuid)
      return result.data
    } catch (error) {
      await Container.get(ErrorHandlerService).error(error)
    }
  }

  async addToGroupRegistration ({ group_uuid, participant_uuid }) {
    try {
      const event_uuid = this.$route.params.event_uuid
      const result = await this.$store.dispatch('registration/addToGroupRegistration', {
        event_uuid,
        group_uuid,
        participant_uuid
      })
    } catch (error) {
      await Container.get(ErrorHandlerService).error(error)
    }
  }

  // Watch the currentParticipants form input. For the fields with option groups update the selections.
  @Watch('currentParticipant', { deep: true })
  onCurrentParticipantChange (payload) {
    const fieldGroupMap = {}
    this.fieldsAssociatedWithOptionGroups.forEach(field => {
      fieldGroupMap[field.field] = field.option_group_uuid
    })

    for (const [fieldUuid, value] of Object.entries(payload)) {
      if (fieldUuid in fieldGroupMap) {
        const groupUuid = fieldGroupMap[fieldUuid]
        if (Array.isArray(value)) {
          for (const option in this.currentParticipantOptionSelections[groupUuid]) {
            this.currentParticipantOptionSelections[groupUuid][option].newValue = value.includes(option) ? 1 : 0
          }
        } else {
          for (const option in this.currentParticipantOptionSelections[groupUuid]) {
            this.currentParticipantOptionSelections[groupUuid][option].newValue = option === value ? 1 : 0
          }
        }
      }
    }
  }

  async deleteParticipant (uuid) {
    try {
      this.deletingParticipant = true
      this.participantToBeDeleted = uuid
      await this.$store.dispatch('registration/deleteRegistration', {
        event_uuid: this.$route.params.event_uuid,
        registration_uuid: uuid,
        data: {
          company_uuid: this.$route.params.company_uuid,
          deletion_reason: 'TODO'
        }
      })
      // if we are deleting the current participant, we will need to update the selected participant.
      if (uuid === this.selectedParticipant) {
        // first try to move forward.
        let nextKey = this.getAdjacentKey()
        // if null try looking backward. You'll always have the parent to fallback on.
        if (!nextKey) {
          nextKey = this.getAdjacentKey(false)
        }
        // update the selected participant.
        this.setParticipant(nextKey)
      }
      delete this.groupParticipantsOptionSelections[uuid]
      delete this.groupParticipantAnswers[uuid]
      delete this.participantSubmissionMap[uuid]
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.participantToBeDeleted = ''
      this.deletingParticipant = false
    }
  }

  async addAdditionalParticipant () {
    try {
      this.addingParticipant = true
      const email = this.spoofEmail(this.setupInfo.email)
      const { uuid: participant_uuid } = await this.createParticipant(email)
      await this.addToGroupRegistration({ group_uuid: this.groupUuid, participant_uuid })
      // since push adds to the end I can just determine the index by taking the newLength and subtracting 1
      const newParticipantAnswers: GroupParticipantFormAnswers = structuredClone(this.formFields)
      if (email !== this.setupInfo.email) {
        newParticipantAnswers.email = ''
      }

      if (this.useParentAnswers) {
        const parentAnswers = this.groupParticipantAnswers[this.groupParentUuid]
        const childrenUuids = Object.keys(this.groupParticipantAnswers).filter(uuid => uuid !== this.groupParentUuid)
        for (const uuid of childrenUuids) {
          for (const field of this.prepopulatedFields) {
            if (field in newParticipantAnswers) {
              newParticipantAnswers[field] = parentAnswers[field]
            }
          }
        }
      }

      this.groupParticipantAnswers = {
        ...this.groupParticipantAnswers,
        [participant_uuid]: newParticipantAnswers
      }
      this.groupParticipantsOptionSelections = {
        ...this.groupParticipantsOptionSelections,
        [participant_uuid]: structuredClone(this.optionGroupsOnForm)
      }
      this.participantSubmissionMap[participant_uuid] = false

      this.participantCounter++
    } catch (error) {
      Container.get(Notification).error('There was an error adding an additional participant')
    } finally {
      this.addingParticipant = false
    }
  }

  spoofEmail (email: string) {
    const emailParts = email.split('@')
    emailParts[0] += `M${this.participantCounter}`
    return emailParts.join('@')
  }

  setupTrackers (participant_uuid: string): void {
    this.groupParticipantAnswers[participant_uuid] = structuredClone(this.formFields)
    this.groupParticipantsOptionSelections[participant_uuid] = structuredClone(this.optionGroupsOnForm)
    this.participantSubmissionMap[participant_uuid] = false
  }

  async startParticipant () {
    try {
      this.processing = true
      const email = this.setupInfo.email
      const { uuid: participant_uuid } = await this.createParticipant(email)
      this.setupTrackers(participant_uuid)
      this.groupParentUuid = participant_uuid
      this.setParticipant(this.groupParentUuid)
      this.groupParticipantAnswers[participant_uuid].email = this.setupInfo.email
      this.setupCompleted = true
      this.page = 2
    } catch (error) {
      if (error.data.error_code === 'REG_EXISTS') {
        Container.get(Notification).error('The email you used already has a record.')
      } else {
        Container.get(Notification).error('There was an error creating your participant')
      }
    } finally {
      this.processing = false
    }
  }

  // I don't want to create my participants until I have to. So I am e
  async startParticipantGroup () {
    try {
      this.processing = true
      // first create our group.
      const { uuid: group_uuid } = await this.createGroupRegistration()
      this.groupUuid = group_uuid

      for (const email of this.groupParticipantEmails) {
        // go create the participant, and add them to the group.
        const { uuid: participant_uuid } = await this.createParticipant(email)
        await this.addToGroupRegistration({ group_uuid, participant_uuid })

        // initialize them in the answer and option dictionaries, as well as the submission tracker.
        this.setupTrackers(participant_uuid)

        // lastly for all emails that aren't the parent (the email in setup) set those to ''
        if (email !== this.setupInfo.email) {
          this.groupParticipantAnswers[participant_uuid].email = ''
        } else {
          this.groupParticipantAnswers[participant_uuid].email = this.setupInfo.email
          this.groupParentUuid = participant_uuid
        }
      }
      this.setParticipant(this.groupParentUuid)
      this.setupCompleted = true
      this.page = 2 // open the register form for the new participant group parent.
    } catch (error) {
      if (error.data.error_code === 'REG_EXISTS') {
        Container.get(Notification).error('The email you used already has a record.')
      } else {
        Container.get(Notification).error('There was an error creating your participant group')
      }
    } finally {
      this.processing = false
    }
  }

  // TODO(zb): This is still fragile. What if a participant makes an error with the parent email?
  // When the total participants is changed we need to scaffold out or update our list of group participants.
  @Watch('setupInfo.totalParticipants')
  async onTotalParticipantsChange (payload) {
    if (payload) {
      if (payload < this.groupParticipantEmails.length) {
        this.groupParticipantEmails = this.groupParticipantEmails.slice(0, payload)
      } else {
        const difference = Math.abs(this.groupParticipantEmails.length - payload)
        for (let i = 0; i < difference; i++) {
          let email = this.setupInfo.email

          if (this.participantCounter > 0) {
            email = this.spoofEmail(this.setupInfo.email)
          }

          this.groupParticipantEmails.push(email)
          this.participantCounter++
        }
      }
    }
  }

  @Watch('useParentAnswers')
  async onUseParentAnswersChange (useParentAnswers: boolean) {
    if (useParentAnswers) {
      const parentAnswers = this.groupParticipantAnswers[this.groupParentUuid]
      const childrenUuids = Object.keys(this.groupParticipantAnswers).filter(uuid => uuid !== this.groupParentUuid)
      for (const uuid of childrenUuids) {
        const answers = this.groupParticipantAnswers[uuid]
        for (const field of this.prepopulatedFields) {
          if (field in answers) {
            answers[field] = parentAnswers[field]
          }
        }
      }
    }
  }
}
