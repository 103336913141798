import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  name: 'MultiFactorRequiredMixin'
})
export default class MultiFactorRequiredMixin extends Vue {
  @Prop({ required: false, type: Boolean, default: true })
  validToken!: boolean|undefined

  @Prop({ required: false, default: '' })
  token!: string

  get mfaToken () {
    return this.token
  }

  set mfaToken (value: string) {
    this.$emit('update:token', value)
  }

  onResendToken () {
    this.$emit('resend')
  }

  onVerifyToken () {
    this.$emit('verify')
  }
}
