import { Component } from 'vue-property-decorator'
import SettingMixin from '@/modules/common/components/mixins/setting.mixin'

@Component({
  name: 'SwitchSetting'
})
export default class SwitchSetting extends SettingMixin {
  regTypeToAdd?: Record<string, any> | null = {}

  search = ''

  get regTypesThatDontHaveSettings () {
    return this.regTypesFiltered.filter(({ value }) => !this.regTypeHasSetting(value))
  }

  get regTypesThatDoHaveSettings () {
    return this.regTypesFiltered.filter(({ value, text }) => this.regTypeHasSetting(value) && text.toLowerCase().includes(this.search.toLowerCase()))
  }

  /**
   * overrideDefaultSetting addes the given reg type to the settings value and initializes it to the provided default. Fallback is false.
   * TODO(zb): will need to change the fallback for different input types.
   * @param uuid {string}
   */
  overrideDefaultSetting (uuid: string) {
    this.regTypeToAdd = {}
    const copy = structuredClone(this.setting)
    copy[uuid] = this.default ?? false
    this.$emit('input', copy)
  }
}
