import moment from 'moment'
import { Component, Vue } from 'vue-property-decorator'
import { AccessLevel } from '../../enums/access-levels.enum'
import { Layout } from '../../enums/layouts.enum'
import { TwoFactorAuthenticationMethod } from '../../enums/two-factor-methods.enum'
import CompanyUser from '../../models/company-user.model'
import languages from '@/bootstrap/global/languages'
import { Module } from '../../enums/modules.enum'
import Container, { Service } from 'typedi'
import SecurityContext from '../../services/security-context.service'
import ErrorHandlerService from '../../services/error-handler.service'
import { EventStatus } from '../../enums/event-status.enum'
import { mapState } from 'vuex'
import { ScanModeEnum } from '@/modules/level-two/enums/scan-mode.enum'
import allowList from '@/bootstrap/global/allowlist'

@Service()
@Component({
  name: 'GtrSuper',
  computed: {
    ...mapState('event', ['event', 'events']),
    ...mapState('security', ['currentUser'])
  }
})
export default class GtrSuper extends Vue {
  events!: Record<string, any>;

  accessLevels: any[] = [
    { value: AccessLevel.COMPANY_ADMIN, text: 'Company Admin' },
    // { value: AccessLevel.COMPANY_REPORT_USER, text: 'Company Report User' },
    { value: AccessLevel.COMPANY_USER, text: 'Company User' }
  ]

  twoFactorAuthenticationMethods: any[] = [
    { value: TwoFactorAuthenticationMethod.EMAIL, text: 'Email' }
  ]

  yesOrNoChoices: any [] = [
    { value: 0, text: 'No' },
    { value: 1, text: 'Yes' }
  ]

  statusOptions = ['Complete', 'Incomplete', 'Waitlisted', 'Pending', 'Canceled', 'Declined', 'Approved', 'Transferred/Pending', 'Transferred', 'Deleted', 'INACTIVE', 'Invited']

  excludedParticipantImportFields = [
    'a2z_id',
    'can_export_certificates',
    'certificate_id',
    'cur_reg_fee',
    'cur_reg_fee_lr',
    'date_canceled',
    'date_registered',
    'date_transferred',
    'deleted_by',
    'deletion_reason',
    'devices_mobile_app',
    'devices_pro_scanner',
    'extra_bcc',
    'highest_submitted_page',
    'highest_submitted_page_lr',
    'import_id',
    'import_uuid',
    'invoice_number',
    'login_key',
    'lr_orders',
    'lr_payment_status',
    'lr_phone',
    'lr_status',
    'onsite_device',
    'onsite_device_status',
    'onsite_notes',
    'onsite_phone',
    'password',
    'password_reset_token',
    'pay_for_another',
    'payment_status_group',
    'payment_total',
    'payment_vault_id',
    'stripe_customer_id',
    'transferred_from',
    'updated_at',
    'payment_details'
  ]

  userLoggedIn () {
    const securityContext = JSON.parse(Container.get(SecurityContext).context())
    if (securityContext) {
      const user = (this as any).currentUser
      if (user) {
        user.logo = user.profile_photo
        return user
      }
    }
    return null
  }

  impersonator () {
    const securityContext = JSON.parse(Container.get(SecurityContext).context())
    if (securityContext) {
      return securityContext.impersonator
    }
    return null
  }

  async stopImpersonating () {
    await this.$store.dispatch('security/stopImpersonating')
    await this.$router.push({ name: 'level-one.dashboard' })
  }

  signInUserLogo () {
    const user = (this as any).currentUser
    if (user) {
      if (user?.profile_photo) {
        return user.profile_photo
      } else {
        return (user.name as string)[0]
      }
    }
  }

  async logout () {
    try {
      this.$store.dispatch('common/showLoader', { value: true })
      await this.$store.dispatch('security/logout')
      this.$router.push({ name: 'security.login' })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$store.dispatch('common/hideLoader')
    }
  }

  profile () {
    const securityContext = JSON.parse(Container.get(SecurityContext).context())
    if (securityContext) {
      if (securityContext.has_company) {
        this.$router.push({
          name: 'level-two.profile',
          params: {
            uuid: securityContext.user.company.uuid
          }
        })
      } else {
        this.$router.push({
          name: 'level-one.profile'
        })
      }
    }
  }

  getAccessLevelOfUser (user: CompanyUser) {
    switch (user.access_level) {
      case AccessLevel.SELF_SERVICE_ADMIN:
        return 'Self Service Admin'
      case AccessLevel.COMPANY_ADMIN:
        return 'Company Admin'
      case AccessLevel.COMPANY_REPORT_USER:
        return 'Company Report User'
      case AccessLevel.COMPANY_USER:
        return 'Company User'
      case AccessLevel.RESELLER_ADMIN:
        return 'Reseller Admin'
      case AccessLevel.SUPER_ADMIN:
        return 'Super Admin'
      default:
        return user.access_level
    }
  }

  getEventStatus (event: any) {
    switch (event.status) {
      case EventStatus.CREATED:
        return 'Created'
      case EventStatus.CREATING:
        return 'Creating'
      case EventStatus.INACTIVE:
        return 'Inactive'
      case EventStatus.PENDING:
        return 'Pending'
    }
  }

  makeSideBarMenu (company_uuid: string, layout: Layout, event_uuid?: string) {
    switch (layout) {
      case Layout.GLOBAL_SOLUTION:
        return this.makeSideBarMenuGlobalSolution(company_uuid)
      case Layout.EVENT:
        if (event_uuid) { return this.makeSideBarMenuEvent(company_uuid, event_uuid) }
        break
    }
  }

  makeSideBarMenuModule (module: string, uuid: string, event_uuid: string) {
    switch (module) {
      case Module.REGISTRATION:
        return this.makeSideBarMenuRegistrationModule(uuid, event_uuid)
      case Module.SURVEYS:
        return this.makeSideBarMenuSureysQuizzesModule(uuid, event_uuid)
      case Module.LEADS:
        return this.makeSideBarMenuLeadsModule(uuid, event_uuid)
    }
  }

  convertDatetimeToUTC (dateToFormat: any) {
    if (typeof dateToFormat === 'string') {
      return moment(dateToFormat)
        .utc()
        .format('YYYY-MM-DD HH:mm')
    } else {
      return moment(new Date(dateToFormat.getTime()))
        .utc()
        .format('YYYY-MM-DD HH:mm')
    }
  }

  convertDatetimeToMMDDYYYY (dateToFormat: any) {
    if (typeof dateToFormat === 'string') {
      return moment(dateToFormat)
        .utc()
        .format('MM-DD-YYYY')
    } else {
      return moment(new Date(dateToFormat.getTime()))
        .utc()
        .format('MM-DD-YYYY')
    }
  }

  convertDatetimeToLocalTimezone (dateToFormat: any) {
    // stop using moment.js :)
    if (typeof dateToFormat === 'string') {
      const localTime = new Date(Date.parse(dateToFormat))
      return localTime
    }
  }

  convertFromEventTimezoneToUtc (dateToFormat: string | Date) {
    if (!dateToFormat) {
      return ''
    }
    if ((this as any)?.event?.timezone) {
      const momentDatetime = moment(dateToFormat).tz((this as any).event.timezone, true)
      return momentDatetime.tz('UTC').format('YYYY-MM-DD HH:mm')
    }
    return moment(dateToFormat).tz('UTC').format('YYYY-MM-DD HH:mm')
  }

  convertFromUtcToEventTimezone (dateToFormat: string) {
    return moment.utc(dateToFormat).tz(((this as any).event.timezone || '')).format('YYYY-MM-DD HH:mm')
  }

  defaultLanguages () {
    return languages
  }

  bytesToSize (bytes: number) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return 'n/a'
    const value = Math.floor(Math.log(bytes) / Math.log(1024))
    const i = parseInt(String(value), 10)
    if (i === 0) return `${bytes} ${sizes[i]})`
    return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
  }

  get statusOptionsLr () {
    const opt = this.statusOptions
    opt.unshift('')
    return opt
  }

  disablePaste (id: string) {
    const input: any = document.getElementById(id)
    if (input) {
      input.onpaste = e => {
        e.preventDefault()
        return false
      }
    }
  }

  env () {
    return process.env.VUE_APP_ENV
  }

  private makeSideBarMenuGlobalSolution (company_uuid: string) {
    return [
      {
        text: 'Dashboard',
        route: {
          name: 'level-two.company.dashboard',
          params: {
            uuid: company_uuid
          },
          meta: {
            permissions: 'company.dashboard.view'
          }
        },
        icon: 'mdi-view-dashboard',
        show: true
      },
      {
        text: 'Manage Account',
        route: {
          name: 'level-two.company.info',
          params: {
            uuid: company_uuid
          },
          meta: {
            permissions: 'company.info.view'
          }
        },
        icon: 'mdi-information-outline',
        show: true
      },
      // {
      //   text: 'Company Performance',
      //   route: {
      //     name: 'level-two.company.performance',
      //     params: {
      //       uuid: company_uuid
      //     },
      //     meta: {
      //       permissions: 'company.info.view'
      //     }
      //   },
      //   icon: 'mdi-view-list',
      //   show: true
      // },
      {
        text: 'Users',
        route: {
          name: 'level-two.company.users',
          params: {
            uuid: company_uuid
          },
          meta: {
            permissions: 'company.users.view'
          }
        },
        icon: 'mdi-account-supervisor-circle',
        show: true
      },
      {
        text: 'Events',
        route: {
          name: 'level-two.company.events',
          params: {
            uuid: company_uuid
          },
          meta: {
            permissions: 'company.dashboard.view' // this may need to be changed
          }
        },
        icon: 'mdi-ticket',
        show: true
      },
      {
        text: 'Company Blacklists',
        route: {
          name: 'level-two.companies.blacklist',
          params: {
            uuid: company_uuid
          },
          meta: {
            permissions: 'company.blacklist.view'
          }
        },
        icon: 'mdi-email',
        show: true
      },
      {
        text: 'A2Z Badges App',
        route: {
          name: 'level-two.printers',
          params: {
            uuid: company_uuid
          },
          meta: {
            permissions: 'company.printers.view'
          }
        },
        icon: 'mdi-receipt',
        show: true
      }, {
        text: 'Fonts',
        route: {
          name: 'level-two.company.fonts',
          params: {
            uuid: company_uuid
          },
          meta: {
            permissions: 'company.info.view'
          }
        },
        icon: 'mdi-format-font',
        show: true
      }
    ]
  }

  getScanMode (scanMode: number | string): ScanModeEnum | number {
    const mode = typeof scanMode === 'string' ? parseInt(scanMode) : scanMode
    switch (mode) {
      case 0:
        return ScanModeEnum.JustScan
      case 1:
        return ScanModeEnum.AccessControl
      case 2:
        return ScanModeEnum.DupeCheck
      case 3:
        return ScanModeEnum.DupeCheckAndAccessControl
      default:
        return mode
    }
  }

  private makeSideBarMenuEvent (uuid: string, event_uuid: string) {
    return [
      {
        text: 'Event Info',
        route: {
          name: 'level-two.event.event-info',
          params: {
            uuid,
            event_uuid
          },
          meta: {
            levels: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.SELF_SERVICE_ADMIN, AccessLevel.RESELLER_ADMIN, AccessLevel.COMPANY_USER]
          }
        },
        icon: 'mdi-domain'
      },
      {
        text: 'Sessions',
        route: {
          name: 'level-two.modules.track.sessions',
          params: {
            uuid,
            event_uuid
          },
          meta: {
            levels: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.SELF_SERVICE_ADMIN, AccessLevel.RESELLER_ADMIN, AccessLevel.COMPANY_USER]
          }
        },
        icon: 'mdi-account-supervisor-circle-outline'
      },
      {
        text: 'Orders',
        route: {
          name: 'level-two.modules.track.orders',
          params: {
            uuid,
            event_uuid
          },
          meta: {
            levels: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.SELF_SERVICE_ADMIN, AccessLevel.RESELLER_ADMIN, AccessLevel.COMPANY_USER]
          }
        },
        icon: 'mdi-barcode-scan'
      },
      {
        text: 'Modules',
        route: {
          name: 'level-two.event.modules',
          params: {
            uuid,
            event_uuid
          },
          meta: {
            levels: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.SELF_SERVICE_ADMIN, AccessLevel.RESELLER_ADMIN, AccessLevel.COMPANY_USER]
          }
        },
        icon: 'mdi-view-module'
      },
      {
        text: 'Participants',
        route: {
          name: 'level-two.event.attendees',
          params: {
            uuid,
            event_uuid
          },
          meta: {
            levels: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.SELF_SERVICE_ADMIN, AccessLevel.RESELLER_ADMIN, AccessLevel.COMPANY_USER]
          }
        },
        icon: 'mdi-account-multiple'
      },
      {
        text: 'Emails',
        route: {
          name: 'level-two.event.emails',
          params: {
            uuid,
            event_uuid
          },
          meta: {
            levels: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.SELF_SERVICE_ADMIN, AccessLevel.RESELLER_ADMIN, AccessLevel.COMPANY_USER]
          }
        },
        icon: 'mdi-email'
      },
      {
        text: 'Reports',
        route: {
          name: 'level-two.event.reports',
          params: {
            uuid,
            event_uuid
          },
          meta: {
            levels: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.SELF_SERVICE_ADMIN, AccessLevel.RESELLER_ADMIN, AccessLevel.COMPANY_USER]
          }
        },
        icon: 'mdi-view-list'
      },
      {
        text: 'Files',
        route: {
          name: 'level-two.event.files',
          params: {
            uuid,
            event_uuid
          },
          meta: {
            levels: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.SELF_SERVICE_ADMIN, AccessLevel.RESELLER_ADMIN, AccessLevel.COMPANY_USER]
          }
        },
        icon: 'mdi-file'
      },
      // {
      //   text: 'Languages',
      //   route: {
      //     name: 'level-two.event.languages',
      //     params: {
      //       uuid,
      //       event_uuid
      //     },
      //     meta: {
      //       levels: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.SELF_SERVICE_ADMIN, AccessLevel.RESELLER_ADMIN]
      //     }
      //   },
      //   icon: 'mdi-web'
      // },
      {
        text: 'Webhooks',
        route: {
          name: 'level-two.event.webhooks',
          params: {
            uuid,
            event_uuid
          },
          meta: {
            levels: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.SELF_SERVICE_ADMIN, AccessLevel.RESELLER_ADMIN, AccessLevel.COMPANY_USER]
          }
        },
        icon: 'mdi-newspaper'
      },
      {
        text: 'Support Check',
        route: {
          name: 'level-two.event.support-check',
          params: {
            uuid,
            event_uuid
          },
          meta: {
            levels: [AccessLevel.SUPER_ADMIN]
          }
        },
        icon: 'mdi-check-circle-outline'
      },
      {
        text: 'Activity Log',
        route: {
          name: 'level-two.event.activity-log',
          params: {
            uuid,
            event_uuid
          },
          meta: {
            levels: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.SELF_SERVICE_ADMIN, AccessLevel.RESELLER_ADMIN, AccessLevel.COMPANY_USER]
          }
        },
        icon: 'mdi-card-text'
      },
      {
        text: 'Design',
        route: {
          name: 'level-two.modules.registration.design',
          params: {
            uuid,
            event_uuid
          },
          meta: {
            levels: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.SELF_SERVICE_ADMIN, AccessLevel.RESELLER_ADMIN, AccessLevel.COMPANY_USER]
          }
        },
        icon: 'mdi-format-color-fill'
      },
      {
        text: 'lr_Design',
        route: {
          name: 'level-two.modules.leads.design',
          params: {
            uuid,
            event_uuid
          },
          meta: {
            levels: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.SELF_SERVICE_ADMIN, AccessLevel.RESELLER_ADMIN, AccessLevel.COMPANY_USER]
          }
        },
        icon: 'mdi-format-color-fill'
      },
      {
        text: 'Settings',
        route: {
          name: 'level-two.event.setting',
          params: {
            uuid,
            event_uuid
          },
          meta: {
            levels: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.SELF_SERVICE_ADMIN, AccessLevel.RESELLER_ADMIN, AccessLevel.COMPANY_USER]
          }
        },
        icon: 'mdi-cog'
      },
      {
        text: 'Integrations',
        route: {
          name: 'level-two.event.syncs',
          params: {
            uuid,
            event_uuid
          },
          meta: {
            levels: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.SELF_SERVICE_ADMIN, AccessLevel.RESELLER_ADMIN, AccessLevel.COMPANY_USER]
          }
        },
        icon: 'mdi-sync'
      },
      {
        text: 'Promote',
        route: {
          name: 'level-two.modules.registration.promote',
          params: {
            uuid,
            event_uuid
          },
          meta: {
            levels: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.SELF_SERVICE_ADMIN, AccessLevel.RESELLER_ADMIN, AccessLevel.COMPANY_USER]
          }
        },
        icon: 'mdi-chart-line-variant'
      }
    ]
  }

  private makeSideBarMenuRegistrationModule (uuid: string, event_uuid: string) {
    return [
      {
        text: 'Dashboard',
        route: {
          name: 'level-two.modules.registration.dashboard',
          params: {
            uuid,
            event_uuid
          }
        },
        icon: 'mdi-view-dashboard'
      },
      {
        text: 'Form',
        route: {
          name: 'level-two.modules.registration.form',
          params: {
            uuid,
            event_uuid
          }
        },
        icon: 'mdi-file-document'
      },
      {
        text: 'Options',
        route: {
          name: 'level-two.modules.registration.options',
          params: {
            uuid,
            event_uuid
          }
        },
        icon: 'mdi-format-list-bulleted'
      },
      {
        text: 'Promo Codes',
        route: {
          name: 'level-two.modules.registration.promo-codes',
          params: {
            uuid,
            event_uuid
          }
        },
        icon: 'mdi-star'
      },
      {
        text: 'Payment',
        route: {
          name: 'level-two.modules.registration.payment',
          params: {
            uuid,
            event_uuid
          }
        },
        icon: 'mdi-credit-card-outline'
      },
      {
        text: 'Pricing Tiers',
        route: {
          name: 'level-two.modules.registration.pricing-tiers',
          params: {
            uuid,
            event_uuid
          }
        },
        icon: 'mdi-folder-table'
      },
      {
        text: 'Content Pages',
        route: {
          name: 'level-two.modules.registration.content-pages',
          params: {
            uuid,
            event_uuid
          }
        },
        icon: 'mdi-selection'
      }
    ]
  }

  private makeSideBarMenuSureysQuizzesModule (uuid: string, event_uuid: string) {
    return [
      {
        text: 'S&Q Builder',
        route: {
          name: 'level-two.modules.evals.surveys.session.index',
          params: {
            uuid,
            event_uuid
          }
        },
        icon: 'mdi-format-list-bulleted'
      },
      {
        text: 'Certificates',
        route: {
          name: 'level-two.modules.evals.certificates',
          params: {
            uuid,
            event_uuid
          }
        },
        icon: 'mdi-star'
      },
      {
        text: 'Design',
        route: {
          name: 'level-two.modules.evals.design',
          params: {
            uuid,
            event_uuid
          },
          meta: {
            levels: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.SELF_SERVICE_ADMIN, AccessLevel.RESELLER_ADMIN, AccessLevel.COMPANY_USER]
          }
        },
        icon: 'mdi-format-color-fill'
      },
      {
        text: 'Settings',
        route: {
          name: 'level-two.modules.evals.settings',
          params: {
            uuid,
            event_uuid
          },
          meta: {
            levels: [AccessLevel.SUPER_ADMIN, AccessLevel.COMPANY_ADMIN, AccessLevel.SELF_SERVICE_ADMIN, AccessLevel.RESELLER_ADMIN, AccessLevel.COMPANY_USER]
          }
        },
        icon: 'mdi-cog'
      }
    ]
  }

  get mini () {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': return true
      case 'sm': return false
      case 'md': return false
      case 'lg': return false
      case 'xl': return false
    }
  }

  get eventUuid () {
    const {
      uuid,
      event_uuid
    } = this.$route.params
    return {
      uuid: uuid ?? null,
      event_uuid: event_uuid ?? null
    }
  }

  private makeSideBarMenuLeadsModule (uuid: string, event_uuid: string) {
    return [
      {
        text: 'Leads by Exhibitor',
        route: {
          name: 'level-two.modules.leads.index',
          params: {
            uuid,
            event_uuid
          }
        },
        icon: 'mdi-data-matrix-scan'
      },
      {
        text: 'Leads by Attendee',
        route: {
          name: 'level-two.modules.leads.by-attendee',
          params: {
            uuid,
            event_uuid
          }
        },
        icon: 'mdi-credit-card-scan-outline'
      },
      {
        text: 'Date Breakdown',
        route: {
          name: 'level-two.modules.leads.date-breakdown',
          params: {
            uuid,
            event_uuid
          }
        },
        icon: 'mdi-calendar'
      },
      {
        text: 'Device Checkout',
        route: {
          name: 'level-two.modules.leads.device-checkout',
          params: {
            uuid,
            event_uuid
          }
        },
        icon: 'mdi-cellphone-information'
      }
    ]
  }

  // check if a url is valid.
  checkURLAndGo (href: string): void {
    // attempt to create a new url.
    const url = new URL(href)
    // check if the basename is in our allow list.
    if (allowList.indexOf(url.origin) === -1) throw new Error('href is not an allowed url')
    // finally set the location to our verified url.
    window.location.href = url.toString()
  }

  handleEmailName (text: string) {
    switch (text) {
      case 'approved':
        return 'Registration Approved'
      case 'declined':
        return 'Registration Declined'
      case 'invited':
        return 'Registration Invitation'
      case 'canceled_email':
        return 'Registration Canceled'
      case 'invite_declined':
        return 'Registration Invite Declined'
      case 'invite_request_email':
        return 'Registration Invites Needing Review'
      case 'invoice':
        return 'Registration Invoice'
      case 'welcome':
        return 'Registration Welcome'
      case 'transfer_email':
        return 'Registration Transfer'
      case 'your_qrcode':
        return 'QR Code for Check-In'
      case 'your_leads':
        return 'Exhibitor Leads Report'
      case 'survey_portal':
        return 'Attendee Survey Login'
      case 'pending':
        return 'Registration Pending'
      case 'lrorders_promotion':
        return 'Lead Retrieval Promotion'
      case 'lrorders_confirmation':
        return 'Lead Retrieval Order Confirmation'
      case 'lrorders_activation_codes':
        return 'Lead Retrieval Activation Codes'
      case 'lr_invoice':
        return 'Lead Retrieval Invoice'
      case 'login_details':
        return 'Registration Login Details'
      default:
        return text.split('_').map(name => name.charAt(0).toUpperCase() + name.slice(1)).join(' ')
    }
  }
}
