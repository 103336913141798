import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import GtrStorage from '@/modules/common/services/storage.service'
import Container from 'typedi'
import { ValidationObserver } from 'vee-validate'
import { Component, Ref, Vue } from 'vue-property-decorator'
import GrtSecurityLayout from '../../layouts/security/security.layout.vue'
import { TwoFactorAuthenticationMethod } from '@/modules/common/enums/two-factor-methods.enum'

@Component({
  name: 'GtrRegisterView'
})
export default class GtrRegisterView extends Vue {
    @Ref()
    readonly observerRegisterForm!: InstanceType<typeof ValidationObserver>

    data () {
      return {
        submitting: false,
        showTermsModal: false,
        register: {
          name: null,
          email: null,
          password: null,
          password_confirmation: null,
          two_factor_enabled: 1,
          two_factor_method: TwoFactorAuthenticationMethod.EMAIL,
          agreeToTerms: false
        }
      }
    }

    created () {
      this.$emit('update:layout', GrtSecurityLayout)
    }

    async submit () {
      const formObserver = this.$refs.observerRegisterForm as any
      const isValid = await formObserver.validate()
      if (isValid) {
        try {
          this.$data.submitting = true
          this.$data.register.admin_base_url = window.location.origin
          const response = await this.$store.dispatch('security/register', this.$data.register)
          if (response.data.uuid) {
            Container.get(GtrStorage).setItem('signedup_email', this.$data.register.email)
            this.$router.push({ name: 'security.check-email' })
          }
        } catch (error) {
          Container.get(ErrorHandlerService).error(error)
          if (error.data) {
            const errors = error.data.errors
            if (errors) {
              this.showServerErrors(errors)
            }
          }
        } finally {
          this.$data.register = {
            name: null,
            email: null,
            password: null,
            password_confirmation: null
          }
          this.observerRegisterForm.reset()
          this.$data.submitting = false
        }
      }
    }

    private showServerErrors (errors: Record<string, string>): void {
      this.observerRegisterForm.setErrors(errors)
    }

    openTermsModal () {
      this.$data.showTermsModal = true
    }

    closeTermsModal () {
      this.$data.showTermsModal = false
    }

    agreeAndCloseTermsModal () {
      this.$data.register.agreeToTerms = true
      this.$data.showTermsModal = false
    }
}
