import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { Component, Ref, Prop, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'

@Component({
  name: 'GtrAddQualifierForm',
  computed: {
    ...mapState('payment', ['payment_processor', 'payment_properties', 'payment_processor_widget', 'payment_settings']),
    ...mapState('registration', ['registrationFees', 'registrationTransactions']),
    ...mapState('event', ['event'])
  }
})
export default class GtrAddQualifierForm extends GtrSuper {
  @Prop({ required: true, type: Boolean, default: false })
    visible: boolean | undefined

  @Prop({ required: true, type: Object, default: {} })
  qualifier: any | undefined

  @Ref()
  readonly observerAddQualifierForm!: InstanceType<typeof ValidationObserver>;

  data () {
    return {
      _registration: null,
      submitting: false,
      showForm: false,
      formWidth: 800,
      elements: '',
      description: '',
      type: 'TRUE_OR_FALSE',
      options: [],
      typeItems: [
        {
          text: 'TRUE_OR_FALSE',
          value: 'TRUE_OR_FALSE'
        },
        {
          text: 'SCALE_1_5',
          value: 'SCALE_1_5'
        },
        {
          text: 'DATE',
          value: 'DATE'
        },
        {
          text: 'RADIO',
          value: 'RADIO'
        },
        {
          text: 'CHECKBOX',
          value: 'CHECKBOX'
        }
      ]
    }
  }

  @Watch('qualifier', { immediate: true })
  onQualifierChange (qualifier: any) {
    if (qualifier) {
      this.setQualifierData(qualifier)
    }
  }

  @Watch('visible', { immediate: true })
  onVisibleValueChange (newVisibleValue: boolean) {
    this.$data.showForm = newVisibleValue
  }

  setQualifierData (qualifier) {
    this.$data.description = qualifier.description
    this.$data.type = qualifier.type
    this.$data.options = qualifier.options
  }

  async mounted () {
    if (this.qualifier) {
      this.setQualifierData(this.qualifier)
    }
    this.cleanFormModel()
    if (this.$data._registration === null) {
      await this.fetchRegistration()
    }
    const event = this.$store.state.event.event
    if (event) {
      this.$data._event = event
    } else {
      await this.fetchEvent()
    }
  }

  private async fetchEvent () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('event/fetchEvent', this.$route.params.event_uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchRegistration () {
    try {
      this.$data.loading = true
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        participant_uuid: this.$route.params.attendee_uuid
      }
      const response = await this.$store.dispatch('registration/getRegistration', payload)
      this.$data._registration = response.data
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  get event_identifier () {
    return this.$data._event.event_identifier
  }

  addOption () {
    this.$data.options.push('') // Add a new empty option
  }

  removeOption (index: number) {
    this.$data.options.splice(index, 1) // Remove the selected option
  }

  async editQualifier () {
    try {
      this.$data.loading = true
      this.$data.submitting = true
      const payload = {
        event_uuid: this.$data._event.uuid,
        participant_uuid: this.$data._registration.uuid,
        qualifier_uuid: this.qualifier.uuid,
        data: {
          description: this.$data.description,
          type: this.$data.type,
          options: this.$data.options
        }
      }
      await this.$store.dispatch('registration/editQualifier', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
      this.$data.submitting = false
      this.cleanFormModel()
      this.onClose()
    }
  }

  cleanFormModel () {
    this.$data.application = ''
    this.$data.cap = 3
    this.observerAddQualifierForm.reset()
  }

  onClose () {
    this.cleanFormModel()
    this.$emit('close')
  }
}
