import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Container } from 'typedi'
import EventService from '../../common/services/event/event.service'

@Module({
  namespaced: true
})
export default class GtrModuleStore extends VuexModule {
  eventModules?: any
  activatedEventModules: Record<string, any> = {}
  eventOverview = {
    identifier: 'OVERVIEW',
    icon: null,
    activeImg: require('@/assets/img/modules/event-overview.svg'),
    inactiveImg: require('@/assets/img/modules/event-overview - grayscale.svg'),
    name: 'Event Overview',
    active: true,
    ready: true,
    submodules: [
      {
        icon: 'mdi-view-dashboard',
        name: 'Dashboard',
        active: true,
        route: {
          name: 'level-two.event.event-info',
          meta: {
            permissions: 'general.dashboard.view'
          }
        }
      },
      {
        icon: 'mdi-view-module',
        name: 'Modules',
        active: true,
        route: {
          name: 'level-two.event.modules',
          meta: {
            permissions: 'events.enable_modules.view'
          }
        }
      },
      {
        icon: 'mdi-account-multiple',
        name: 'Participants',
        active: true,
        route: {
          name: 'level-two.event.attendees',
          meta: {
            permissions: 'general.participants.view'
          }
        }
      },
      {
        icon: 'mdi-email-outline',
        name: 'Emails',
        active: true,
        route: {
          name: 'level-two.event.emails',
          meta: {
            permissions: 'general.emails.view'
          }
        }
      },
      {
        icon: 'mdi-view-list',
        name: 'Reports',
        active: true,
        route: {
          name: 'level-two.event.reports',
          meta: {
            permissions: 'general.reports.view'
          }
        }
      },
      {
        icon: 'mdi-credit-card-outline',
        name: 'Payments',
        active: false,
        visible: false,
        route: {
          name: 'level-two.event.payments',
          meta: {
            permissions: 'general.payments.view'
          }
        }
      },
      {
        icon: 'mdi-file',
        name: 'Files',
        active: true,
        route: {
          name: 'level-two.event.files',
          meta: {
            permissions: 'general.files.view'
          }
        }
      },
      {
        icon: 'mdi-newspaper',
        name: 'Webhooks',
        active: true,
        route: {
          name: 'level-two.event.webhooks',
          meta: {
            permissions: 'general.webhooks.view'
          }
        }
      },
      {
        icon: 'mdi-account-multiple-outline',
        name: 'Integrations',
        active: true,
        route: {
          name: 'level-two.event.syncs',
          meta: {
            permissions: 'general.integrations.view'
          }
        }
        // },
        // {
        //   icon: 'mdi-barcode-scan',
        //   name: 'MicroScanners',
        //   active: true,
        //   route: {
        //     name: 'level-two.event.scans',
        //     meta: {
        //       permissions: 'general.microscanners.view'
        //     }
        //   }
      }
    ]
  }

  gtrModules = [
    {
      identifier: 'REGISTRATION',
      name: 'Registration',
      icon: null,
      activeImg: require('@/assets/img/modules/register.svg'),
      inactiveImg: require('@/assets/img/modules/register - grayscale.svg'),
      darkColor: 'var(--neon-green-100)',
      lightColor: 'var(--neon-green-100)',
      active: false,
      ready: true,
      route: {
        name: 'level-two.modules.registration.dashboard',
        meta: {
          permissions: ['register.access.view']
        }
      },
      submodules: [
        {
          icon: 'mdi-view-dashboard',
          name: 'Dashboard',
          active: true,
          route: {
            name: 'level-two.modules.registration.dashboard',
            meta: {
              permissions: ['register.access.view']
            }
          }
        }, {
          icon: 'mdi-file-document',
          name: 'Form',
          active: true,
          route: {
            name: 'level-two.modules.registration.form',
            meta: {
              permissions: ['register.forms.view']
            }
          }
        }, {
          icon: 'mdi-view-list',
          name: 'Options',
          active: true,
          route: {
            name: 'level-two.modules.registration.options',
            meta: {
              permissions: 'register.options.view'
            }
          }
        }, {
          icon: 'mdi-format-color-fill',
          name: 'Design',
          active: true,
          route: {
            name: 'level-two.modules.registration.design',
            meta: {
              permissions: 'register.design.view'
            }
          }
        }, {
          icon: 'mdi-bullhorn-outline',
          name: 'Promote',
          active: true,
          route: {
            name: 'level-two.modules.registration.promote',
            meta: {
              permissions: ['register.promote.view']
            }
          }
        }, {
          icon: 'mdi-barcode',
          name: 'Promo Codes',
          active: true,
          route: {
            name: 'level-two.modules.registration.promo-codes',
            meta: {
              permissions: ['register.promo_codes.view']
            }
          }
        }, {
          icon: 'mdi-credit-card-outline',
          name: 'Payment',
          active: true,
          route: {
            name: 'level-two.modules.registration.payment',
            meta: {
              permissions: ['register.payments.view']
            }
          }
        }, {
          icon: 'mdi-folder-table',
          name: 'Pricing Tiers',
          active: true,
          route: {
            name: 'level-two.modules.registration.pricing-tiers',
            meta: {
              permissions: ['register.pricing_tiers.view']
            }
          }
        }, {
          icon: 'mdi-selection',
          name: 'Content Pages',
          active: true,
          route: {
            name: 'level-two.modules.registration.content-pages',
            meta: {
              permissions: ['register.content_pages.view']
            }
          }
        }, {
          icon: 'mdi-cog',
          name: 'Settings',
          active: true,
          route: {
            name: 'level-two.modules.registration.settings',
            meta: {
              permissions: ['register.registration_settings.view']
            }
          }
        }
      ]
    },
    {
      identifier: 'LEADS',
      name: 'Lead Retrieval',
      active: false,
      ready: true,
      activeImg: require('@/assets/img/modules/leads.svg'),
      inactiveImg: require('@/assets/img/modules/leads - grayscale.svg'),
      darkColor: 'var(--gold-100)',
      lightColor: 'var(--gold-100)',
      route: {
        name: 'level-two.modules.leads.index',
        meta: {
          permissions: 'leads.access.view'
        }
      },
      submodules: [
        {
          icon: 'mdi-view-dashboard',
          name: 'Dashboard',
          active: true,
          route: {
            name: 'level-two.modules.leads.index',
            meta: {
              permissions: ['leads.access.view']
            }
          }
        }, {
          icon: 'mdi-tune',
          name: 'Setup',
          active: true,
          route: {
            name: 'level-two.modules.leads.setup',
            meta: {
              permissions: ['leads.setup.view']
            }
          }
        },
        // }, {
        //   icon: 'mdi-palette-outline',
        //   name: 'Style',
        //   active: true,
        //   route: {
        //     name: 'level-two.modules.leads.style',
        //     meta: {
        //       permissions: ['leads.style.view']
        //     }
        //   }
        // }, {
        {
          icon: 'mdi-format-color-fill',
          name: 'Design',
          active: true,
          route: {
            name: 'level-two.modules.leads.design',
            meta: {
              permissions: ['leads.design.view']
            }
          }
        }, {
          icon: 'mdi-star',
          name: 'Promote',
          active: true,
          route: {
            name: 'level-two.modules.leads.promote'
          }
        }, {
          icon: 'mdi-cellphone-check',
          name: 'Device Check-in/Out',
          active: true,
          route: {
            name: 'level-two.modules.leads.device-check-in-out',
            meta: {
              permissions: ['leads.device_check_in_out.view']
            }
          }
        }, {
          icon: 'mdi-email-outline',
          name: 'Emails',
          active: true,
          route: {
            name: 'level-two.event.emails',
            meta: {
              permissions: 'general.emails.view'
            }
          }
        }, {
          icon: 'mdi-view-list',
          name: 'Reports',
          active: true,
          route: {
            name: 'level-two.modules.leads.reports',
            meta: {
              permissions: ['leads.lead_retrieval_reports.view']
            }
          }
        },
        {
          icon: 'mdi-credit-card-outline',
          name: 'Payment',
          active: true,
          route: {
            name: 'level-two.modules.leads.payment',
            meta: {
              permissions: ['leads.payment.view']
            }
          }
        },
        {
          icon: 'mdi-cog',
          name: 'Settings',
          active: true,
          route: {
            name: 'level-two.modules.leads.settings',
            meta: {
              permissions: ['leads.settings.view']
            }
          }
        }
      ]
    },
    {
      identifier: 'SESSION_TRACKING',
      name: 'Attendance Tracking',
      active: false,
      ready: true,
      activeImg: require('@/assets/img/modules/track.svg'),
      inactiveImg: require('@/assets/img/modules/track - grayscale.svg'),
      darkColor: 'var(--gold-200)',
      lightColor: 'var(--gold-200)',
      route: {
        name: 'level-two.modules.track.index',
        meta: {
          permissions: 'track.access.view'
        }
      },
      submodules: [
        {
          icon: 'mdi-view-dashboard',
          name: 'Dashboard',
          active: true,
          route: {
            name: 'level-two.modules.track.index',
            meta: {
              permissions: ['track.access.view']
            }
          }
        }, {
          icon: 'mdi-account-multiple',
          name: 'Manage Participants',
          active: true,
          route: {
            name: 'level-two.event.attendees', // Track participants do not exist yet
            meta: {
              permissions: 'general.participants.view'
            }
          }
        }, {
          icon: 'mdi-account-supervisor-circle-outline',
          name: 'Manage Sessions',
          active: true,
          route: {
            name: 'level-two.modules.track.sessions',
            meta: {
              permissions: ['track.sessions.view']
            }
          }
        },
        {
          icon: 'mdi-email-outline',
          name: 'Emails',
          active: true,
          route: {
            name: 'level-two.event.emails',
            meta: {
              permissions: 'general.emails.view'
            }
          }
        },
        {
          icon: 'mdi-view-list',
          name: 'Reports',
          active: true,
          route: {
            name: 'level-two.modules.track.reports', // Track reports do not exist yet
            meta: {
              permissions: 'track.attendance_tracking_reports.view'
            }
          }
        },
        {
          icon: 'mdi-cog',
          name: 'Settings',
          active: true,
          route: {
            name: 'level-two.modules.track.settings',
            meta: {
              permissions: ['track.settings.view']
            }
          }
        }
      ]
    },
    {
      identifier: 'BADGES',
      name: 'Badge Printing',
      active: false,
      ready: true,
      activeImg: require('@/assets/img/modules/badges.svg'),
      inactiveImg: require('@/assets/img/modules/badges - grayscale.svg'),
      darkColor: 'var(--electric-purple-50)',
      lightColor: 'var(--electric-purple-50)',
      route: {
        name: 'level-two.modules.badges.badges',
        meta: {
          permissions: 'badges.access.view'
        }
      },
      submodules: [
        // {
        //   icon: 'mdi-view-dashboard',
        //   name: 'Dashboard',
        //   active: true,
        //   route: {
        //     name: 'level-two.modules.badges.dashboard',
        //     meta: {
        //       permissions: ['badges.access.view']
        //     }
        //   }
        // },
        {
          icon: 'mdi-calendar-account',
          name: 'Upload & Edit Badges',
          active: true,
          route: {
            name: 'level-two.modules.badges.badges',
            meta: {
              permissions: 'badges.upload_edit_badges.view'
            }
          }
        },
        {
          icon: 'mdi-cog',
          name: 'Check-in Setup',
          active: true,
          route: {
            name: 'level-two.modules.badges.settings',
            meta: {
              permissions: 'badges.check_in_setup.view'
            }
          }
        }
      ]
    },
    {
      identifier: 'SURVEYS',
      name: 'Assessments & Certifications',
      active: false,
      ready: true,
      activeImg: require('@/assets/img/modules/evals.svg'),
      inactiveImg: require('@/assets/img/modules/evals - grayscale.svg'),
      darkColor: 'var(--teal-100)',
      lightColor: 'var(--teal-100)',
      route: {
        name: 'level-two.modules.evals.surveys.general.index',
        meta: {
          permissions: 'evals.access.view'
        }
      },
      submodules: [
        {
          icon: 'mdi-view-dashboard',
          name: 'Dashboard',
          active: true,
          route: {
            name: 'level-two.modules.evals.dashboard'
          }
        }, {
          icon: 'mdi-view-list',
          name: 'S&Q Builder',
          active: true,
          route: {
            name: 'level-two.modules.evals.surveys.session.index'
          }
        }, {
          icon: 'mdi-certificate-outline',
          name: 'Certificates',
          active: true,
          route: {
            name: 'level-two.modules.evals.certificates.index'
          }
        }, {
          icon: 'mdi-format-color-fill',
          name: 'Design',
          active: true,
          route: {
            name: 'level-two.modules.evals.design.index',
            meta: {
              permissions: 'evals.design.view'
            }
          }
        }, {
          icon: 'mdi-cog',
          name: 'Settings',
          active: true,
          route: {
            name: 'level-two.modules.evals.settings.index',
            meta: {
              permissions: 'evals.settings.view'
            }
          }
        }
      ]
    },
    {
      identifier: 'CALL_FOR_PAPERS',
      name: 'Abstracts',
      active: false,
      ready: false,
      activeImg: require('@/assets/img/modules/abstracts.svg'),
      inactiveImg: require('@/assets/img/modules/abstracts - grayscale.svg'),
      darkColor: 'var(--gtr-abstracts-dark)',
      lightColor: 'var(--gtr-abstracts-light)',
      route: {
        name: 'level-two.modules.call_4_papers.index'
      },
      submodules: []
    },
    {
      identifier: 'EXHIBITS',
      name: 'Exhibits',
      active: false,
      ready: false,
      activeImg: require('@/assets/img/modules/exhibits.svg'),
      inactiveImg: require('@/assets/img/modules/exhibits - grayscale.svg'),
      darkColor: 'var(--gtr-exhibits-dark)',
      lightColor: 'var(--gtr-exhibits-light)',
      route: {
        name: 'level-two.modules.exhibits.index'
      },
      submodules: []
    },
    {
      identifier: 'HOTELS',
      name: 'Hotels',
      active: false,
      ready: false,
      activeImg: require('@/assets/img/modules/placeholder.svg'),
      inactiveImg: require('@/assets/img/modules/placeholder.svg'),
      darkColor: 'var(--gtr-hotels-dark)',
      lightColor: 'var(--gtr-hotels-light)',
      route: {
        name: 'level-two.modules.hotels.index'
      },
      submodules: []
    }
    // },
    // {
    //   identifier: 'PROMOTE',
    //   name: 'Promote',
    //   active: false,
    //   activeImg: require('@/assets/img/modules/placeholder.svg'),
    //   inactiveImg: require('@/assets/img/modules/placeholder.svg'),
    //   route: {
    //     name: 'level-two.modules.registration.promote'
    //   },
    //   submodules: []
    // }
  ]

  support = {
    identifier: 'SUPPORT',
    icon: null,
    activeImg: require('@/assets/img/modules/support.svg'),
    inactiveImg: null,
    name: 'Support',
    ready: true,
    active: true,
    route: {
      name: 'level-two.modules.support',
      meta: {
        permissions: 'general.support.view'
      }
    }
  }

  get surveyModuleActive (): boolean {
    return ('SURVEYS' in this.activatedEventModules) && this.activatedEventModules.SURVEYS.enabled
  }

  @Mutation
  UPDATE_GTR_MODULES (payload: any) {
    this.gtrModules = payload
  }

  @Mutation
  SET_EVENT_MODULES (payload: any) {
    this.activatedEventModules = payload
  }

  @Action({ rawError: true })
  async fetchEventModules (payload: any) {
    const response = await Container.get(EventService).getEventModules(payload)
    this.context.commit('SET_EVENT_MODULES', response.data)
  }

  /**
   * activateModule should set a post request to activate the module.
   * modules should then be refetched.
   */
  @Action({ rawError: true })
  async activateModule (payload: any) {
    const { event_uuid, body } = payload
    const response = await Container.get(EventService).activateModule(event_uuid, body)
    await this.context.dispatch('fetchEventModules', event_uuid)
    return response
  }

  @Action({ rawError: true })
  async deactivateModule (payload: any) {
    const { event_uuid, body } = payload
    const response = await Container.get(EventService).deactivateModule(event_uuid, body)
    this.context.dispatch('fetchEventModules', event_uuid)
    return response
  }

  @Mutation
  SET_SUBMODULE_VISIBILITY (payload: { routeName: string; active: boolean }) {
    const { routeName, active } = payload
    const index = this.eventOverview.submodules.map(submodule => (submodule?.route?.name || '').toLowerCase()).indexOf(routeName)
    if (index > -1) {
      this.eventOverview.submodules[index].active = active
    }
  }
}
