import { Component, Mixins } from 'vue-property-decorator'
import GtrAdminLayout from '@/modules/common/views/layouts/level-one/admin/admin.layout.vue'
import { mapState } from 'vuex'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import GtrNewAdminUserForm from './forms/new/new.form.vue'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import GtrEditAdminUserForm from './forms/edit/edit.form.vue'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import MultiFactorMixin from '@/modules/common/components/mixins/multifactor.mixin'

@Component({
  name: 'GtrAdminUsersView',
  computed: {
    ...mapState('adminUser', ['users', 'users_inactive'])
  },
  components: {
    'gtr-new-adminuser-form': GtrNewAdminUserForm,
    'gtr-edit-adminuser-form': GtrEditAdminUserForm
  }
})
export default class GtrAdminUsersView extends Mixins(MultiFactorMixin, GtrSuper) {
  users!: Record<string, any>

  users_inactive!: Record<string, any>

  data () {
    return {
      search: '',
      loading: false,
      working: false,
      table: {
        headers: [
          { text: 'Name', align: 'start', sortable: false, value: 'name' },
          { text: 'Email', value: 'email' },
          { text: 'User Group', value: 'access_level' },
          { text: 'Active', value: 'active' },
          { text: 'Actions', value: 'actions', searchable: false, sortable: false, width: '90px' }
        ]
      },
      forms: {
        showNewAdminUserForm: false,
        showEditAdminUserForm: false,
        showEventAccessForm: false
      },
      userToDelete: null,
      userToEdit: null,
      userToCorrelate: null
    }
  }

  get allUsers (): any[] {
    const users: any[] = []
    for (let i = 0; i < this.users.length; i++) {
      users.push(this.users[i])
    }
    for (let i = 0; i < this.users_inactive.length; i++) {
      users.push(this.users_inactive[i])
    }
    return users
  }

  onFormsChange () {
    // eslint-disable-next-line no-console
    return null
  }

  created () {
    this.$emit('update:layout', GtrAdminLayout)
  }

  async mounted () {
    this.fetchUsers()
    this.fetchUsersInactive()
    this.loadCompanies()
  }

  handleShowNewUserForm () {
    this.$data.forms.showNewAdminUserForm = true
    this.valid_two_factor_token = false
    this.sendTwoFactorCode()
  }

  handleCloseNewUserForm () {
    this.$data.forms.showNewAdminUserForm = false
    this.valid_two_factor_token = false
  }

  handleShowEditUserForm (user: any) {
    this.$data.forms.showEditAdminUserForm = true
    this.$data.userToEdit = user
    this.valid_two_factor_token = false
    this.sendTwoFactorCode()
  }

  handleCloseEditUserForm () {
    this.$data.forms.showEditAdminUserForm = false
    this.$data.userToEdit = null
    this.valid_two_factor_token = false
  }

  async handleShowEventCorrelatorForm (user: any) {
    await this.$store.dispatch('security/getUser', { user_uuid: user.uuid })
    // this.$data.userToCorrelate = user
    this.$data.forms.showEventAccessForm = true
    this.$bus.$emit('open-event-access')
  }

  handleCloseEventCorrelatorForm () {
    this.$data.forms.showEventAccessForm = false
    this.$bus.$emit('close-event-access')
  }

  emitLoadEventAccess (payload: boolean) {
    if (payload) {
      this.$bus.$emit('gtr-event-access-load', this.$data.userToCorrelate)
    }
  }

  allowsDelete (user: any) {
    return this.userLoggedIn() && this.userLoggedIn().uuid !== user.uuid
  }

  async unlockUser (user_uuid: string) {
    const data = {
      bad_login_count: 0,
      lockout_ip: null,
      lockout_until: null
    }
    try {
      await this.updateUser(user_uuid, data)
      Container.get(Notification).success('User successfully unlocked')
    } catch (e) {
      Container.get(Notification).error('There was a problem unlocking this user. Please try again.')
    }
  }

  async updateUser (user_uuid: string, data: any) {
    return await this.$store.dispatch('adminUser/updateUser', { user_uuid, data })
  }

  isUserLockedOut (lockout_until: string) {
    if (new Date(lockout_until).getTime() > new Date().getTime()) {
      return true
    }
    return false
  }

  async handleUserUpdate (user_uuid: string, action: string) {
    try {
      this.$data.working = true
      const payload: any = {
        user_uuid,
        active: action === 'ACTIVATE'
      }
      await this.$store.dispatch('adminUser/updateUserStatus', payload)
      await this.$store.dispatch('adminUser/fetchAdminUsersInactive')
      Container.get(Notification).success('User successfully updated.')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.working = false
    }
  }

  handleUserDelete (user: any) {
    this.$data.userToDelete = user
    this.valid_two_factor_token = false
    this.sendTwoFactorCode()
  }

  async handleDeleteUserAction (payload: any) {
    if (payload.confirm) {
      try {
        this.$data.working = true
        const payload: any = {
          user_uuid_param: this.$data.userToDelete.uuid
        }
        await this.$store.dispatch('adminUser/removeUser', payload)
        await this.$store.dispatch('adminUser/fetchAdminUsersInactive')
        Container.get(Notification).success('User successfully deleted.')
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.working = false
      }
    }
    this.$data.userToDelete = null
  }

  async impersonateUser (user) {
    try {
      this.$data.loading = true
      await this.$store.dispatch('security/impersonateUser', user.uuid)
      this.$router.go(0)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchUsers () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('adminUser/fetchAdminUsers')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchUsersInactive () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('adminUser/fetchAdminUsersInactive')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async loadCompanies () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('event/loadEvents')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }
}
