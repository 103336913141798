import Container from 'typedi'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import RegistrationService from '../services/registration.service'
import router from '@/bootstrap/router/router'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import { group } from 'console'
@Module({
  namespaced: true
})
export default class RegistrationStore extends VuexModule {
  registrationsFinishedDelete: any = {}
  importErrors: any = {}
  pastImports: any = {}
  registration: any = {}
  registrationAuditLog: any = {}
  registrationLeadsAppCodes: any = {}
  registrationLeads: any = {}
  registrationQualifiers: any = {}
  registrationEmailsSent: any = {}
  registrationTransactions: any = {}
  registrationFees: any = {}
  LRFees: any = {}
  registrationRequestLog: any = {}
  registrations: any = {}
  registrationsSearches: any = {}
  currentImport: any = {}
  registrationsDeleted: any = {}
  participantSearchResults: any = {}
  previousExternalSyncs: any = {}
  providerData: any = {}
  registrationAnalytics: any = {}
  participantsGroup: any = {}
  groupFees: any = {}
  savedSearches: any = []
  groups: any = []

  // #region Mutations
  @Mutation
  SET_REGISTRATIONS_SEARCHES (payload: any) {
    this.registrationsSearches = payload
  }

  @Mutation
  SET_IMPORT_ERRORS (payload: any) {
    this.importErrors = payload
  }

  @Mutation
  SET_CURRENT_IMPORT (payload: any) {
    this.currentImport = payload
  }

  @Mutation
  SET_PAST_IMPORTS (payload: any) {
    this.pastImports = payload
  }

  @Mutation
  SET_REGISTRATIONS_DELETED (payload: any) {
    this.registrationsDeleted = payload
  }

  @Mutation
  SET_REGISTRATION (payload: any) {
    this.registration = payload
  }

  @Mutation
  SET_REGISTRATION_REQUEST_LOG (payload: any) {
    this.registrationRequestLog = payload
  }

  @Mutation
  SET_REGISTRATION_AUDIT_LOG (payload: any) {
    this.registrationAuditLog = payload
  }

  @Mutation
  SET_REGISTRATION_LEADS_APP_CODES (payload: any) {
    this.registrationLeadsAppCodes = payload
  }

  @Mutation
  SET_REGISTRATION_LEADS (payload: any) {
    this.registrationLeads = payload
  }

  @Mutation
  SET_REGISTRATION_QUALIFIERS (payload: any) {
    this.registrationQualifiers = payload
  }

  @Mutation
  SET_REGISTRATION_EMAIL_SENT (payload: any) {
    this.registrationEmailsSent = payload
  }

  @Mutation
  SET_REGISTRATION_FEES (payload: any) {
    this.registrationFees = payload
  }

  @Mutation
  SET_LR_FEES (payload: any) {
    this.LRFees = payload
  }

  @Mutation
  SET_REGISTRATION_TRANSACTIONS (payload: any) {
    this.registrationTransactions = payload
  }

  @Mutation
  SET_REGISTRATION_ANALYTICS (payload: any) {
    this.registrationAnalytics = payload
  }

  @Mutation
  SET_PREVIOUS_EXTERNAL_SYNCS (payload: any) {
    this.previousExternalSyncs = payload
  }

  @Mutation
  SET_PROVIDER_DATA (payload: any) {
    this.providerData = payload
  }

  @Mutation
  SET_REGISTRATIONS_FINISHED (payload: any) {
    this.registrationsFinishedDelete = payload
  }

  @Mutation
  SET_REGISTRATIONS_FINISHED_DELETE (payload: any) {
    this.registrationsFinishedDelete = payload
  }

  @Mutation
  SET_PARTICIPANT_SEARCH_RESULTS (payload: any) {
    this.participantSearchResults = payload
  }

  @Mutation
  SET_PARTICIPANTS_GROUP (payload: any) {
    this.participantsGroup = payload
  }

  @Mutation
  SET_GROUPS (payload: any) {
    this.groups = payload
  }

  @Mutation
  SET_GROUP_FEES (payload: any) {
    this.groupFees = payload
  }

  @Mutation
  SET_SAVED_SEARCHES (payload: any) {
    this.savedSearches = payload
  }

  // #endregion

  // #region Actions

  @Action({ rawError: true })
  async importFile (payload: any) {
    const { company_uuid, fileData, data } = payload
    const response = await Container.get(RegistrationService).importFile(company_uuid, fileData, data)
    return response
  }

  @Action({ rawError: true })
  async updateOptionQty (payload: any) {
    const { option_group_uuid, option_uuid, event_uuid, participant_uuid, qty, removeOtherSelections } = payload
    const response = await Container.get(RegistrationService).updateOptionQty(option_group_uuid, option_uuid, event_uuid, participant_uuid, qty, removeOtherSelections)
    return response
  }

  @Action({ rawError: true })
  async getParticipantImportSample (payload: any) {
    const { event_uuid, type } = payload
    const response = await Container.get(RegistrationService).getParticipantImportSample(event_uuid, type)
    return response
  }

  @Action({ rawError: true })
  async doSearch (payload: any) {
    const { event_uuid, data } = payload
    const response = await Container.get(RegistrationService).doSearch(event_uuid, data)
    return response
  }

  @Action({ rawError: true })
  async getSearches (event_uuid: string) {
    const response = await Container.get(RegistrationService).getSearches(event_uuid)
    this.context.commit('SET_SAVED_SEARCHES', response.data)
    return response
  }

  @Action({ rawError: true })
  async saveSearch (payload: any) {
    const { event_uuid, data } = payload
    const response = await Container.get(RegistrationService).saveSearch(event_uuid, data)
    await this.context.dispatch('getSearches', event_uuid)
    return response
  }

  @Action({ rawError: true })
  async updateSearch (payload: any) {
    const { event_uuid, savedSearchUuid, data } = payload
    const response = await Container.get(RegistrationService).updateSearch(event_uuid, savedSearchUuid, data)
    await this.context.dispatch('getSearches', event_uuid)
    return response
  }

  @Action({ rawError: true })
  async getParticipantSearchResults (payload: any) {
    const { event_uuid, search_uuid } = payload
    const response = await Container.get(RegistrationService).getParticipantSearchResults(event_uuid, search_uuid)
    this.context.commit('SET_PARTICIPANT_SEARCH_RESULTS', response.data)
    return response
  }

  @Action({ rawError: true })
  async bulkApproveOrDecline (payload: any) {
    const { event_uuid, records, status } = payload
    const response = await Container.get(RegistrationService).bulkApproveOrDecline(event_uuid, records, status)
    return response
  }

  @Action({ rawError: true })
  async getRegistrations (payload: any) {
    const { event_uuid } = payload
    const response = await Container.get(RegistrationService).getRegistrations(event_uuid)
    this.context.commit('setRegistrationsState', response.data)
    return response
  }

  @Action({ rawError: true })
  async getRegistrationsSearches (payload: any) {
    const { event_uuid } = payload
    const response = await Container.get(RegistrationService).getRegistrationsSearches(event_uuid)
    this.context.commit('SET_REGISTRATIONS_SEARCHES', response.data)
    return response
  }

  @Action({ rawError: true })
  async getImport (payload: any) {
    const { event_uuid, import_uuid, data } = payload
    const response = await Container.get(RegistrationService).getImport(event_uuid, import_uuid)
    if (data && data.poll) {
      if (response.data.status === 'FINISHED') {
        this.context.commit('SET_CURRENT_IMPORT', response.data)
      } else {
        await new Promise(resolve => setTimeout(resolve, 1000))
        this.context.dispatch('getImport', payload)
      }
    }
    this.context.commit('SET_CURRENT_IMPORT', response.data)
    return response
  }

  @Action({ rawError: true })
  async getPastImports (payload: any) {
    const { event_uuid, dataType } = payload
    const response = await Container.get(RegistrationService).getPastImports(event_uuid, dataType)
    this.context.commit('SET_PAST_IMPORTS', response.data)
    return response
  }

  @Action({ rawError: true })
  async getRegistrationsDeleted (payload: any) {
    const { event_uuid } = payload
    const response = await Container.get(RegistrationService).getRegistrationsDeleted(event_uuid)
    this.context.commit('SET_REGISTRATIONS_DELETED', response.data)
    return response
  }

  @Action({ rawError: true })
  async createRegistration (payload: any) {
    const { event_uuid, data } = payload
    const response = await Container.get(RegistrationService).createRegistration(event_uuid, data)
    return response
  }

  @Action({ rawError: true })
  async compRegistration (payload: any) {
    const { event_uuid, participant_uuid, group_uuid, comped } = payload
    const response = await Container.get(RegistrationService).compRegistration(event_uuid, participant_uuid, comped)
    this.context.dispatch('getRegistrationFees', { event_uuid, participant_uuid })
    if (group_uuid) {
      this.context.dispatch('getGroupFees', payload)
    }
    return response
  }

  @Action({ rawError: true })
  async removePromoCode (payload: any) {
    const { event_uuid, participant_uuid } = payload
    const response = await Container.get(RegistrationService).removePromoCode(event_uuid, participant_uuid)
    this.context.dispatch('getRegistrationFees', { event_uuid, participant_uuid })
    this.context.dispatch('getRegistrationAuditLog', { event_uuid, participant_uuid })
    return response
  }

  @Action({ rawError: true })
  async updateImport (payload: any) {
    const { event_uuid, import_uuid, data } = payload
    const response = await Container.get(RegistrationService).updateImport(event_uuid, import_uuid, data)
    if (data.start_import) {
      try {
        await this.context.dispatch('startImport', payload)
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
        return error
      }
    }
    return response
  }

  @Action({ rawError: true })
  async updateAppCode (payload: any) {
    const { event_uuid, participant_uuid, app_code_uuid, data } = payload
    const response = await Container.get(RegistrationService).updateAppCode(event_uuid, participant_uuid, app_code_uuid, data)
    return response
  }

  @Action({ rawError: true })
  async startImport (payload: any) {
    const { event_uuid, import_uuid, data } = payload
    const response = await Container.get(RegistrationService).startImport(event_uuid, import_uuid, data)
    data.poll = true
    this.context.dispatch('getImport', payload)
    return response
  }

  @Action({ rawError: true })
  async createAppCode (payload: any) {
    const { event_uuid, participant_uuid, data } = payload
    const response = await Container.get(RegistrationService).createAppCode(event_uuid, participant_uuid, data)
    data.poll = true
    this.context.dispatch('getRegistrationLeadsAppCodes', payload)
    return response
  }

  @Action({ rawError: true })
  async createQualifier (payload: any) {
    const { event_uuid, participant_uuid, data } = payload
    const response = await Container.get(RegistrationService).createQualifier(event_uuid, participant_uuid, data)
    data.poll = true
    this.context.dispatch('getRegistrationQualifiers', payload)
    return response
  }

  @Action({ rawError: true })
  async editQualifier (payload: any) {
    const { event_uuid, participant_uuid, qualifier_uuid, data } = payload
    const response = await Container.get(RegistrationService).editQualifier(event_uuid, participant_uuid, qualifier_uuid, data)
    data.poll = true
    this.context.dispatch('getRegistrationQualifiers', payload)
    return response
  }

  @Action({ rawError: true })
  async applyPromoCode (payload: any) {
    const { event_uuid, participant_uuid, group_uuid, data } = payload
    const response = await Container.get(RegistrationService).applyPromoCode(event_uuid, participant_uuid, data)
    this.context.dispatch('getRegistrationFees', { event_uuid, participant_uuid })
    if (group_uuid) {
      this.context.dispatch('getGroupFees', payload)
    }
    this.context.dispatch('getRegistrationAuditLog', { event_uuid, participant_uuid })
    return response
  }

  @Action({ rawError: true })
  async getRegistration (payload: any) {
    const addedQueryParam = router.currentRoute.path.indexOf('delete') !== -1 ? '?with_trashed=1' : ''
    const { event_uuid, participant_uuid } = payload
    const response = await Container.get(RegistrationService).getRegistration(event_uuid, participant_uuid, addedQueryParam)
    this.context.commit('SET_REGISTRATION', response.data)
    return response
  }

  @Action({ rawError: true })
  async createGroup (event_uuid: any) {
    const response = await Container.get(RegistrationService).createGroup(event_uuid)
    return response.data
  }

  @Action({ rawError: true })
  async getRequestLog (payload: any) {
    const { event_uuid, participant_uuid, request_id } = payload
    const response = await Container.get(RegistrationService).getRequestLog(event_uuid, participant_uuid, request_id)
    this.context.commit('SET_REGISTRATION_REQUEST_LOG', response.data)
    return response
  }

  @Action({ rawError: true })
  async getRegistrationAuditLog (payload: any) {
    const addedQueryParam = router.currentRoute.path.indexOf('delete') !== -1 ? '?with_trashed=1' : ''
    const { event_uuid, participant_uuid } = payload
    const response = await Container.get(RegistrationService).getRegistrationAuditLog(event_uuid, participant_uuid, addedQueryParam)
    this.context.commit('SET_REGISTRATION_AUDIT_LOG', response.data)
    return response
  }

  @Action({ rawError: true })
  async getRegistrationLeadsAppCodes (payload: any) {
    const addedQueryParam = router.currentRoute.path.indexOf('delete') !== -1 ? '?with_trashed=1' : ''
    const { event_uuid, participant_uuid } = payload
    const response = await Container.get(RegistrationService).getRegistrationLeadsAppCodes(event_uuid, participant_uuid, addedQueryParam)
    this.context.commit('SET_REGISTRATION_LEADS_APP_CODES', response.data)
    return response
  }

  @Action({ rawError: true })
  async getRegistrationLeads (payload: any) {
    const addedQueryParam = router.currentRoute.path.indexOf('delete') !== -1 ? '?with_trashed=1' : ''
    const { event_uuid, participant_uuid } = payload
    const response = await Container.get(RegistrationService).getRegistrationLeads(event_uuid, participant_uuid, addedQueryParam)
    this.context.commit('SET_REGISTRATION_LEADS', response.data)
    return response
  }

  @Action({ rawError: true })
  async getRegistrationQualifiers (payload: any) {
    const addedQueryParam = router.currentRoute.path.indexOf('delete') !== -1 ? '?with_trashed=1' : ''
    const { event_uuid, participant_uuid } = payload
    const response = await Container.get(RegistrationService).getRegistrationQualifiers(event_uuid, participant_uuid, addedQueryParam)
    this.context.commit('SET_REGISTRATION_QUALIFIERS', response.data)
    return response
  }

  @Action({ rawError: true })
  async getRegistrationEmailsSent (payload: any) {
    const addedQueryParam = router.currentRoute.path.indexOf('delete') !== -1 ? '?with_trashed=1' : ''
    const { event_uuid, participant_uuid } = payload
    const response = await Container.get(RegistrationService).getRegistrationEmailsSent(event_uuid, participant_uuid, addedQueryParam)
    this.context.commit('SET_REGISTRATION_EMAIL_SENT', response.data)
    return response
  }

  @Action({ rawError: true })
  async getRegistrationFees (payload: any) {
    const addedQueryParam = router.currentRoute.path.indexOf('delete') !== -1 ? '?with_trashed=1' : ''
    const { event_uuid, participant_uuid } = payload
    const response = await Container.get(RegistrationService).getRegistrationFees(event_uuid, participant_uuid, addedQueryParam)
    this.context.commit('SET_REGISTRATION_FEES', response.data)
    return response
  }

  @Action({ rawError: true })
  async getLRFees (payload: any) {
    const addedQueryParam = '?form=lr_orders'
    const { event_uuid, participant_uuid } = payload
    const response = await Container.get(RegistrationService).getRegistrationFees(event_uuid, participant_uuid, addedQueryParam)
    this.context.commit('SET_LR_FEES', response.data)
    return response
  }

  @Action({ rawError: true })
  async getRegistrationTransactions (payload: any) {
    const addedQueryParam = router.currentRoute.path.indexOf('delete') !== -1 ? '?with_trashed=1' : ''
    const { event_uuid, participant_uuid } = payload
    const response = await Container.get(RegistrationService).getRegistrationTransactions(event_uuid, participant_uuid, addedQueryParam)
    this.context.commit('SET_REGISTRATION_TRANSACTIONS', response.data)
    return response
  }

  // Action to get and set the Group Fees
  @Action({ rawError: true })
  async getGroupFees (payload: any) {
    const { event_uuid, group_uuid } = payload
    const response = await Container.get(RegistrationService).getGroupFees(event_uuid, group_uuid)
    this.context.commit('SET_GROUP_FEES', response.data)
    return response
  }

  @Action({ rawError: true })
  async getRegistrationAnalytics (payload: any) {
    const { event_uuid, participant_uuid } = payload
    const response = await Container.get(RegistrationService).getRegistrationAnalytics(event_uuid, participant_uuid)
    this.context.commit('SET_REGISTRATION_ANALYTICS', response.data)
    return response
  }

  @Action({ rawError: true })
  async getPreviousExternalSyncs (payload: any) {
    const { event_uuid, participant_uuid } = payload
    const response = await Container.get(RegistrationService).getPreviousExternalSyncs(event_uuid, participant_uuid)
    this.context.commit('SET_PREVIOUS_EXTERNAL_SYNCS', response.data)
    return response
  }

  @Action({ rawError: true })
  async getProviderData (payload: any) {
    const { event_uuid, participant_uuid, provider } = payload
    const response = await Container.get(RegistrationService).getProviderData(event_uuid, participant_uuid, provider)
    this.context.commit('SET_PROVIDER_DATA', response.data)
    return response
  }

  @Action({ rawError: true })
  async approveDeclineRegistration (payload: any) {
    const { event_uuid, registration_uuid, data } = payload
    const response = await Container.get(RegistrationService).approveDeclineRegistration(event_uuid, registration_uuid, data)
    return response
  }

  @Action({ rawError: true })
  async editRegistration (payload: any) {
    const { event_uuid, registration_uuid, data, send_email_override, skip_fetches = false } = payload
    const response = await Container.get(RegistrationService).editRegistration(event_uuid, registration_uuid, data, send_email_override)
    if (!skip_fetches) {
      this.context.dispatch('getRegistration', { event_uuid, participant_uuid: registration_uuid })
      this.context.dispatch('getRegistrationAuditLog', { event_uuid, participant_uuid: registration_uuid })
    }
    return response
  }

  @Action({ rawError: true })
  async downloadInvoice (payload: any) {
    const { event_uuid, participant_uuid, data } = payload
    const response = await Container.get(RegistrationService).downloadInvoice(event_uuid, participant_uuid, data)
    return response
  }

  @Action({ rawError: true })
  async downloadSurveysResponses (payload: any) {
    const { event_uuid, participant_uuid } = payload
    const response = await Container.get(RegistrationService).downloadSurveysResponses(event_uuid, participant_uuid)
    return response
  }

  @Action({ rawError: true })
  async updateRecords (payload: any) {
    const { event_uuid, data } = payload
    const response = await Container.get(RegistrationService).updateRecords(event_uuid, data)
    this.context.commit('SET_REGISTRATIONS_FINISHED', response.data)
    return response
  }

  @Action({ rawError: true })
  async deleteRegistrations (payload: any) {
    const { event_uuid, data } = payload
    const response = await Container.get(RegistrationService).deleteRegistrations(event_uuid, data)
    this.context.commit('SET_REGISTRATIONS_FINISHED_DELETE', response.data)
    this.context.dispatch('getRegistrations', { event_uuid })
    return response
  }

  @Action({ rawError: true })
  async deleteRegistration (payload: any) {
    const { event_uuid, registration_uuid, data } = payload
    const response = await Container.get(RegistrationService).deleteRegistration(event_uuid, registration_uuid, data)
    return response
  }

  @Action({ rawError: true })
  async getParticipantsGroup (payload: any) {
    const { event_uuid, group_uuid } = payload
    const response = await Container.get(RegistrationService).getParticipantsGroup(event_uuid, group_uuid)
    this.context.commit('SET_PARTICIPANTS_GROUP', response.data)
    return response
  }

  @Action({ rawError: true })
  async updateParticipantsGroup (payload: any) {
    const { event_uuid, group_uuid, data } = payload
    const response = await Container.get(RegistrationService).updateParticipantsGroup(event_uuid, group_uuid, data)
    await this.context.dispatch('getParticipantsGroup', payload)
    return response
  }

  @Action({ rawError: true })
  async addParticipantToGroup (payload: any) {
    const { event_uuid, group_uuid, participant_uuid, data } = payload
    const response = await Container.get(RegistrationService).addParticipantToGroup(event_uuid, group_uuid, participant_uuid, data)
    await this.context.dispatch('getParticipantsGroup', payload)
    return response
  }

  @Action({ rawError: true })
  async removeParticipantFromGroup (payload: any) {
    const { event_uuid, group_uuid, participant_uuid, data } = payload
    const response = await Container.get(RegistrationService).removeParticipantFromGroup(event_uuid, group_uuid, participant_uuid, data)
    await this.context.dispatch('getParticipantsGroup', payload)
    return response
  }

  @Action({ rawError: true })
  async getGroups (payload: any) {
    const { event_uuid } = payload
    const response = await Container.get(RegistrationService).getGroups(event_uuid)
    this.context.commit('SET_GROUPS', response.data)
    return response
  }

  @Action({ rawError: true })
  async createGroupRegistration (event_uuid: string) {
    return Container.get(RegistrationService).createGroup(event_uuid)
  }

  @Action({ rawError: true })
  async addToGroupRegistration ({ event_uuid, group_uuid, participant_uuid }): Promise<any> {
    return Container.get(RegistrationService).addParticipantToGroup(event_uuid, group_uuid, participant_uuid, {})
  }
  // #endregion
}
