import { Component, Prop, Watch } from 'vue-property-decorator'
import MultiFactorRequiredMixin from '@/modules/common/components/mixins/multifactor-required'

export type GtrConfirmationActionPayload ={
  confirm: boolean;
  reason?: {
    isReason: boolean;
    reason: string;
  };
}

@Component({
  name: 'GtrConfirmation'
})
export default class GtrConfirmation extends MultiFactorRequiredMixin {
    @Prop({ required: true, type: Boolean, default: false })
    visible: boolean|undefined

    @Prop({ required: false, type: String, default: 'Confirmation' })
    title: string|undefined

    @Prop({ required: false, type: String, default: '' })
    message: string|undefined

    @Prop({ required: false, type: String, default: 'Reject' })
    disagreeText: string | undefined

    @Prop({ required: false, type: String, default: 'Agree' })
    agreeText: string | undefined

    @Prop({ required: true, type: Boolean, default: false })
    reasonable: boolean | undefined

    @Prop({ required: false, type: String, default: '' })
    reasonText: string | undefined

    @Prop({ required: false, type: Number, default: 290 })
    width: number | undefined

    data () {
      return {
        dialog: false,
        reason: null
      }
    }

    @Watch('visible', { immediate: true })
    onVisibilityChange (newValue: boolean) {
      this.$data.dialog = newValue
    }

    handleClose () {
      this.$data.dialog = false
      this.$data.reason = null
      this.$data.reasonText = false
      const observer = (this.$refs.observer as any)
      observer.reset()
      this.$emit('action', { confirm: false })
    }

    handleAgree () {
      this.$emit('action', { confirm: true, reason: { isReason: this.reasonText !== null, reason: this.$data.reason } })
      this.$data.dialog = false
      this.$data.reason = null
      this.$data.reasonText = false
      const observer = (this.$refs.observer as any)
      observer.reset()
    }
}
