import { render, staticRenderFns } from "./company-info.view.vue?vue&type=template&id=0af9b32c&scoped=true&"
import script from "./company-info.view.ts?vue&type=script&lang=ts&"
export * from "./company-info.view.ts?vue&type=script&lang=ts&"
import style0 from "./company-info.view.vue?vue&type=style&index=0&id=0af9b32c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0af9b32c",
  null
  
)

export default component.exports