import { AccessLevel } from '@/modules/common/enums/access-levels.enum'

const haveLowerAccessLevel = (roleOne: string, roleTwo: string) => {
  const accessLevels: any[] = [
    { level: 1, role: AccessLevel.SUPER_ADMIN },
    { level: 2, role: AccessLevel.RESELLER_ADMIN },
    { level: 3, role: AccessLevel.COMPANY_ADMIN },
    { level: 3, role: AccessLevel.SELF_SERVICE_ADMIN },
    { level: 4, role: AccessLevel.COMPANY_USER }
  ]
  if (roleOne === AccessLevel.SUPER_ADMIN) {
    return true
  }
  let roleOneAccessLevelValue = 0
  let roleTwoAccessLevelValue = 0
  let i = 0; let j = 0
  while (i < 4) {
    if (accessLevels[i].role === roleOne) {
      roleOneAccessLevelValue = accessLevels[i].level
    }
    i++
  }
  while (j < 4) {
    if (accessLevels[j].role === roleTwo) {
      roleTwoAccessLevelValue = accessLevels[j].level
    }
    j++
  }
  return roleOneAccessLevelValue <= roleTwoAccessLevelValue
}

export default haveLowerAccessLevel
