import Container, { Service } from 'typedi'
import { AxiosResponse } from 'axios'
import IHttpClientGetParameters from '@/modules/common/contracts/http-client/http-client-get-parameters.interface'
import HttpClient from '@/modules/common/services/http-client.service'
import IHttpClientPostParameters from '@/modules/common/contracts/http-client/http-client-post-parameters.interface'

@Service()
export default class AttendeeService {
  public async getAttendees (event_uuid: any): Promise<AxiosResponse<any>> {
    const httpParams: IHttpClientGetParameters = {
      url: `event/${event_uuid}/participants`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get<any>(httpParams)
    return response
  }

  public async getAttendeesListView (
    event_uuid: string,
    options: {
      limit?: number;
      skip?: number;
      listViewFields?: any[];
      status?: string;
      referral_code?: string;
      statusName?: string;
      customField?: { field: string; value: string };
      promo_code?: string;
      meta?: number;
      requiredFields?: string[];
      optionSelection?: { option_group_uuid: string; option_uuid: string; status?: boolean };
    } = {}
  ): Promise<AxiosResponse<any>> {
    const {
      limit = 1000,
      skip = 0,
      listViewFields,
      status,
      referral_code,
      statusName,
      customField,
      promo_code,
      meta,
      requiredFields,
      optionSelection
    } = options

    let fieldStr = ''
    if (listViewFields) {
      for (const item in listViewFields) {
        fieldStr += `&fields[]=${listViewFields[item].field}`
      }
    }

    let filterStr = ''
    if (status) {
      filterStr += `&filter[status]=${status}`
    }
    if (referral_code) {
      filterStr += `&filter[referral_code]=${referral_code}`
      if (status && statusName) {
        filterStr += `&filter[${statusName}]=${status}`
      }
    }
    if (customField) {
      filterStr += `&filter[${customField.field}]=${customField.value}`
    }
    if (promo_code) {
      filterStr += `&filter[promo_code]=${promo_code}`
    }
    if (optionSelection) {
      filterStr += `&filter[${optionSelection.option_group_uuid}]=${optionSelection.option_uuid}`
      if (optionSelection.status) {
        filterStr += `&filter[status]=${optionSelection.status}`
      }
    }
    if (meta) {
      fieldStr += '&meta=true'
    }

    const requiredFieldStr = ''
    if (requiredFields) {
      for (const item in requiredFields) {
        fieldStr += `&required_fields[]=${requiredFields[item]}`
      }
    }

    const httpParams: IHttpClientGetParameters = {
      url: `event/${event_uuid}/participants-list-view?limit=${limit}&skip=${skip}${fieldStr}${filterStr}${requiredFieldStr}`,
      requireToken: true
    }

    const response = await Container.get(HttpClient).get<any>(httpParams)
    return response
  }

  public async exportParticipantScans (event_uuid: any): Promise<AxiosResponse<any>> {
    const httpParams: IHttpClientGetParameters = {
      url: `event/${event_uuid}/sessions/attendance-counts/participants/export`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get<any>(httpParams)
    return response
  }

  public async addAttendee (event_uuid: any, data: any): Promise<any> {
    const httpParams: IHttpClientPostParameters = {
      url: `event/${event_uuid}/participant`,
      requireToken: true,
      payload: data
    }
    const response = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async deleteAttendees (payload: any): Promise<any> {
    const httpParams: IHttpClientPostParameters = {
      url: `event/${payload.event_uuid}/participants`,
      requireToken: true,
      config: {
        headers: {
          environment: 'dev'
        },
        data: {
          records: payload.records
        }
      }
    }
    const response = await Container.get(HttpClient).delete(httpParams)
    return response
  }

  public async getAttendee (payload: any): Promise<AxiosResponse<any>> {
    const httpParams: IHttpClientGetParameters = {
      url: `event/${payload.event_uuid}/participant/${payload.attendee_uuid}${payload.queryParam}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get<any>(httpParams)
    return response
  }

  public async getAttendeeFees (payload: any): Promise<AxiosResponse<any>> {
    const httpParams: IHttpClientGetParameters = {
      url: `event/${payload.event_uuid}/participant/${payload.attendee_uuid}/fees${payload.queryParam}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get<any>(httpParams)
    return response
  }

  public async getDeletedAttendees (event_uuid: any): Promise<AxiosResponse<any>> {
    const httpParams: IHttpClientGetParameters = {
      url: `event/${event_uuid}/participants/deleted`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get<any>(httpParams)
    return response
  }

  public async getAttendeeActivityLog (payload: any): Promise<AxiosResponse<any>> {
    const httpParams: IHttpClientGetParameters = {
      url: `event/${payload.event_uuid}/participant/${payload.attendee_uuid}/audit-logs${payload.queryParam}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get<any>(httpParams)
    return response
  }

  public async searchAttendees (payload: any): Promise<AxiosResponse<any>> {
    const httpParams: IHttpClientGetParameters = {
      url: `event/${payload.event_uuid}/participants/search`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get<any>(httpParams)
    return response
  }
}
