import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import Container, { Service } from 'typedi'
import { mapGetters, mapState } from 'vuex'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'

@Service()
@Component({
  name: 'DesignPageMixin',
  computed: {
    ...mapState('design', ['design', 'designFields', 'design_templates']),
    ...mapGetters('option', ['registrationTypes'])
  }
})
export default class DesignPageMixin extends Vue {
  design!: Record<string, any> // vuex binding
  designFields!: Record<string, any> // vuex binding
  registrationTypes!: Array<any> // vuex binding

  // internal state.
  templateToUse = 'default'

  designData: Record<string, any> = {}

  template: Record<string, any> = {}

  templateSettings: Record<string, any> = {
    template1: {
      background_image: {
        type: 'text',
        description: 'The full screen background image of the site',
        value: '',
        label: 'Background Image'
      }
    }
  }

  templateItems = [
    {
      text: 'Default',
      value: 'default'
    }
  ]

  // computed properties
  get designLoaded () {
    if (this.design) {
      this.$data._currentlyDeployedDevUUID = this.design.uuid
    }
    return (
      Object.keys(this.designFields).length > 0 &&
      this.registrationTypes.length > 0 &&
      Object.keys(this.design).length > 0
    )
  }

  // watchers

  // hooks
  data () {
    return {
      designVersion: 'default'
    }
  }

  async mounted () {
    await this.$store.dispatch('design/getDesignTemplates')
  }

  // methods.
  updateDesignVersion (uuid: string) {
    this.$data.designVersion = uuid
  }

  getItemsOfFontByTemplate (template = 'default'): Array<{ value: string; text: string }> {
    const fonts: any[] = []
    if (this.designFields) {
      if (this.designFields[template]) {
        Object.keys(this.designFields[template]).map((key: any) => {
          if (key.split('_')[0] === template && this.designFields[template][key].type === 'font') {
            Object.keys(this.designFields[template][key].options).map((option: any) => {
              const font: any = {
                value: option,
                text: this.designFields[template][key].options[option]
              }
              if (!fonts.includes(font)) {
                fonts.push(font)
              }
            })
          }
        })
      }
    }
    return fonts
  }
}
